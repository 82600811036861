/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import TagManager from "react-gtm-module";
import { polyfill } from 'es6-promise';
import $ from "jquery"

import App from './app/App';
import * as serviceWorker from './serviceWorker';
import store from './redux/Store'
import { getAppVersionNumber, getTagManagerArgs, isProduction } from "./helpers/GeneralHelper";
import { initTrackJs } from "./services/track/ErrorTracker";

polyfill();
window.jQuery = $;
const history = createBrowserHistory();

let checkoutAppC = window.hasOwnProperty('checkoutAppContext') ? window.checkoutAppContext : {};
let gtmEnabled = true, isProd = isProduction();
if (checkoutAppC.hasOwnProperty("gtmEnabled")) {
    gtmEnabled = checkoutAppC.gtmEnabled;
}
if (checkoutAppC.hasOwnProperty("production")) {
    isProd = checkoutAppC.production;
}

if (gtmEnabled) {
    TagManager.initialize(getTagManagerArgs());
}

if (isProd) {
    if (window.hasOwnProperty('isInHub') && window.isInHub === false) {
        // app is loaded from the Website
        // we enable tracking only if the user is logged in
        if (window.hasOwnProperty('loggedInUser') && window.loggedInUser === true) {
            initTrackJs('checkout-prod', getAppVersionNumber());
        }
    } else {
        // this is not loaded from Website, is standalone checkout so it means the user is logged in already
        initTrackJs('checkout-prod', getAppVersionNumber());
    }
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter history={history}>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
