import {
    LOCAL_APP_VERSION,
    LOCAL_BRANCH_NAME,
    LOCAL_STORAGE_PAYPAL_DATA,
    LOCAL_STORAGE_TRACK_DATA, LS_REGISTERED_PURCHASE_AMOUNT
} from "../constants/LocalStorage";
import {
    getAccountType,
    getLastOrderId,
    getLocalStorageSegmentationInfo,
    getUserPrivacyPreferences,
    isInHub,
    shouldViewUpgrade
} from "./AppHelper";
import {
    APP_HASH_PATH, APP_BROWSER_ROUTES, APP_VERSION,
    CHECKOUT_PAGE_TYPE, CHROME_RECORDER_APP, HUB_VERSION,
    INDICATIVE_COOKIE_NAME,
    POST_MESSAGES,
    WEVIDEO_PLANS
} from "../constants/AppGlobal";
import LocalStorageHelper from "./LocalStorageHelper";
import Bowser from "bowser";
import {getTierByProductCode} from "./PlansHelper";
import URLHelper from "./URLHelper";
import {getApplicationBaseUrl} from "../config/config.env.variables";

export const getURLParameters = (url) => {
    let result={};

    if(typeof url === "undefined" || (url.indexOf("?") === -1 && url.indexOf("http") !== -1)){
        return result;
    }

    let params = url.substring(1 + url.indexOf('?')),
        vars = params.split("&");

    for ( let i = 0; i < vars.length; i++) {
        if (vars[i].indexOf("=") > -1) {
            let pair = [vars[i].substr(0, vars[i].indexOf("=")), vars[i].substr(1 + vars[i].indexOf("="))];
            result[pair[0]] = pair[1];
        }
    }

    return result;
};

export const getEnv = () => {
    return process.env.NODE_ENV;
};

export const getURLParameter = (name, stripSlashAtEnd) => {
    if (typeof stripSlashAtEnd === "undefined") {
        stripSlashAtEnd = false;
    }

    let params = getURLParameters(window.location.toString()),
        key;

    for (key in params) {
        if (key === name && params.hasOwnProperty(key)) {
            let result = params[key];
            if (result.indexOf('#') > 0) {
                result = result.substr(0, result.indexOf('#'));
            }

            if (stripSlashAtEnd && result.length > 0 && result.substr(result.length - 1) === "/") {
                result = result.substr(0, result.length - 1);
            }

            return result;
        }
    }

    return "";
};

/* istanbul ignore next */
export const getAppVersionNumber = () => {
    let appVersion = localStorage.getItem(LOCAL_APP_VERSION);
    /* istanbul ignore else */
    if (appVersion === null) {
        const scripts = document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i++) {
            let src = scripts[i].getAttribute('src');
            if (src && src.indexOf("devx/new-checkout") > 0) {
                src = src.substr(src.indexOf("devx/new-checkout") + 5, src.length - 1);
                src = src.substr(src.indexOf("/") + 1, src.length - 1);
                appVersion = src.substr(0, src.indexOf("/"));
            } else if (src && src.indexOf("branch") > 0) {
                src = src.substr(src.indexOf("branch") + 7, src.length - 1);
                src = src.substr(src.indexOf("/") + 1, src.length - 1);
                appVersion = src.substr(0, src.indexOf("/"));
            } else {
                appVersion =  "-1";
            }
        }

        const appScript = document.getElementById("appSrc");
        if(appScript != null){
        	const dataVersion = appScript.getAttribute('data-version');

        	if(dataVersion !== null) {
               appVersion = dataVersion;
        	}
        }
     

        localStorage.setItem(LOCAL_APP_VERSION, appVersion);
    }

    return appVersion;
};

export const getEnvironmentMode = () => {
    const prod =
        window.location.href.indexOf("www.wevideo") > 0 ||
        window.location.href.indexOf("//wevideo.com") > 0 ||
        window.location.href.indexOf("//eu.wevideo.com") > 0 ||
        window.location.href.indexOf("//prod01.wevideo.com") > 0 ||
        window.location.href.indexOf("dualstack.tmp-prod-lb") > 0;

    return prod ? "prod" : "test";
};

export const isEuEnvironment = () => {
    return window.location.href.indexOf("//eu.wevideo.com") > 0;
}

export const isProduction = () => {
    if (window.hasOwnProperty("checkoutAppContext") && window.checkoutAppContext.hasOwnProperty("production")) {
        return window.checkoutAppContext.production;
    }

    // fallback to the old domain check
    return getEnvironmentMode() === "prod"
};

export const isInternalSignUp = (emailValue) => {
    return !isProduction() && (emailValue.indexOf("testing.wevideo.com") !== -1 ||emailValue.indexOf("testing.wevideo.edu") !== -1);
}

export const getUserPrivacySettingsArray = (stateSettings) => {
    let userSettings = getUserPrivacyPreferences();
    let returnArray = [];

    userSettings.forEach(function(element, index) {
        if (typeof stateSettings[element.key] !== 'undefined') {
            let tmp = {};
            tmp['id'] = element.id;
            tmp['value'] = stateSettings[element.key];
            returnArray.push(tmp);
        }
    });

    return returnArray;
};


export const getFacebookAppId = () => {
    if (window.location.hostname === "localhost") {
        return "1516219188416536";
    } else if (isProduction()) {
        return "527449224089666";
    } else {
        return "854711451303764";
    }
};

export const getTagManagerArgs = () => {
    if(window.location.hostname === "www.wevideo.com") {
        return {
            gtmId: "GTM-MRL78F",
            dataLayer: []
        }
    }

    return {
        gtmId: "GTM-MRL78F",
        dataLayer: [],
        auth: "VDhhelbI-O2jSRjNLwyLdw",
        preview: "env-71"
    }
};

export const roundToTwoDecimals = (num) => {
    let numStr = num.toString();
    if (numStr.includes('.')) {
        let arrSplit = numStr.split('.');
        numStr = arrSplit[0] + '.';
        if (arrSplit[1].length === 0) {
            numStr = numStr + '00';
        } else if (arrSplit[1].length === 1) {
            numStr = numStr + arrSplit[1] + '0';
        } else {
            numStr = numStr + arrSplit[1].substring(0, 2);
        }
    } else {
        if (num !== 0) {
            numStr = numStr + '.00';
        } else {
            return num;
        }
    }

    return numStr;
};

export const formatPriceToFixed = (price, fractionDigits = 2) => {
    const x = price.toFixed(fractionDigits);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isObjectEmpty = (obj) => {
    let key;
    for(key in obj) {
        if(typeof obj.hasOwnProperty !== "undefined" && obj.hasOwnProperty(key)) {
            return false
        } else if(Object.prototype.hasOwnProperty.call(obj, key)) {
            return false;
        }
    }
    return true;
};

export const isLocalHost = () => {
    return (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") && (window.location.port === "3000" || window.location.port === "3001");
};

export const getUrlExtension = (url) => {
    // eslint-disable-next-line no-useless-escape
    return url.split(/[#?]/)[0].split('.').pop().trim();
};

export const loadedInIframe = () => {
    try {
        if (isLocalHost()) {
            // on localhost we want it differently because hosts are different on hub and cart
            return window.self !== window.top;
        }
        if (window.self !== window.parent && parentIsSameOrigin()) {
            return window.parent.hasOwnProperty('UserSession');
        } else {
            return false;
        }
    } catch (e) {
        /* istanbul ignore next */
        return true;
    }
};

export const parentIsSameOrigin = () => {
    try {
        return window.parent.location.host === window.location.host;
    } catch (e) {
        return false;
    }
};

export const  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sendPostMessage = (message) => {
	/* We expect to get an object. Create one if we just receive a value */
	if(typeof message !== "object"){
		message = {
			event: message	
		};
	}
	
    console.log('TRYING TO SEND POST MESSAGE:..', message);
    if (loadedInIframe() === true) {
    	let destination = process.env.REACT_APP_HUB_URL || window.location.origin;
        let parentWindow = window.parent;

        /* istanbul ignore else */
    	if(typeof process.env.REACT_APP_HUB_URL === "undefined"){
    		/* We're not running locally, with the hub on another port */
    		if (window.location.origin !== parentWindow.location.origin) {
    			return false;
    	    }
    	}
      
        let exists = false;
        Object.keys(POST_MESSAGES).forEach(function(key) {
            if (POST_MESSAGES[key] === message.event) {
                exists = true;
            }
        });
        if (exists === true) {
            parentWindow.postMessage(JSON.stringify(message), destination);
            console.log('POST MESSAGE SENT to ' + destination);
            return true;
        }
    }
    return false;
};

export const sendUrlChangedPostMessage = (location) => {
    if (loadedInIframe() === true) {
        let message = {
            event: POST_MESSAGES.CHECKOUT_NAVIGATION,
            pathname: location.pathname,
            search: location.search,
            hash: location.hash
        };
        sendPostMessage(message);
    }
};

export const contains = (innerString, outerString) => {
    return outerString.indexOf(innerString) >= 0;
};

export const deviceName = (user_agent) => {
    if(contains("Android", user_agent) ||
        contains("webOS", user_agent) ||
        contains("iPhone", user_agent) ||
        contains("iPad", user_agent) ||
        contains("iPod", user_agent) ||
        contains("BlackBerry", user_agent) ||
        contains("Windows Phone", user_agent)) {
        return "Mobile";
    }

    return "Web";
};

export const trackPageValue = () => {
    return loadedInIframe() === true ? "upgrade_plans" : "website_plans";
};

export const getLocationFormLocalStorageTrack = () => {
    const locationTrackStore = LocalStorageHelper.get(LOCAL_STORAGE_TRACK_DATA);
    let locationTrack = "locationNotSet";

    /* istanbul ignore else */
    if(
        typeof locationTrackStore !== "undefined"
        && locationTrackStore !== null
        && !isObjectEmpty(locationTrackStore)
        && locationTrackStore.hasOwnProperty("location")
        && locationTrackStore.location !== ""
    ) {
        locationTrack = locationTrackStore.location;
        LocalStorageHelper.update(LOCAL_STORAGE_TRACK_DATA, "location", "");
    }

    return locationTrack;
};

export const isHTMLEditorSupported = (browser) => {
    if (
        window.chrome !== null &&
        window.chrome !== undefined &&
        window.navigator.vendor === "Google Inc."
    ) {
        return true;
    }

    if (
        (window.hasOwnProperty('opr') && !!window.opr && window.opr.hasOwnProperty('addons') &&  !!window.opr.addons) ||
        !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0
    ) {
        return true;
    }

    if (typeof InstallTrigger !== "undefined") {
        //Firefox
        return true;
    }

    if (browser.msedge && (browser.version >= 14.14393 || window.WinRT)) {
        return true;
    }

    return false;
}

export const isUnsupportedBrowserVersion = () => {
    const browser = Bowser;

    switch (browser.name) {
        case "Chrome":
            return browser.version < 40;
        case "Firefox":
            return browser.version < 40;
        case "Opera":
            return browser.version < 40;
        default:
            return !isHTMLEditorSupported(browser);
    }
};
export const isFromReferAFriend = () => {
    return window.hasOwnProperty("isFromReferAFriend") && window.isFromReferAFriend === true
};

export const setPaypalCheckoutData = (totalAmount, productCode, productName, tier, isCCRequiredTrial, planIsTrial) => {
    const paypalData = {
        totalAmount: totalAmount,
        productCode: productCode,
        productName: productName,
        tier: tier,
        hasPaypalPayment: true,
        isCCRequiredTrial: isCCRequiredTrial,
        planIsTrial: planIsTrial,
    };

    sessionStorage.setItem(LOCAL_STORAGE_PAYPAL_DATA, JSON.stringify(paypalData));
};

export const getPaypalCheckoutData = () => {
    let paypalData = sessionStorage.getItem(LOCAL_STORAGE_PAYPAL_DATA);
    /* istanbul ignore else */
    if (paypalData !== null) {
        paypalData = JSON.parse(paypalData);
    }
    let productCode = WEVIDEO_PLANS.FREE_PLAN, totalAmount = 0, hasPaypalPayment = false, isCCRequiredTrial = false, planIsTrial = false;
    let tier = getTierByProductCode(productCode), productName = getAccountType();

    /* istanbul ignore else */
    if (typeof paypalData !== 'undefined' && paypalData !== null) {
        /* istanbul ignore else */
        if (paypalData.hasOwnProperty('totalAmount')) {
            totalAmount = paypalData.totalAmount;
        }
        /* istanbul ignore else */
        if (paypalData.hasOwnProperty('productCode')) {
            productCode = paypalData.productCode;
        }
        /* istanbul ignore else */
        if (paypalData.hasOwnProperty('productName')) {
            productName = paypalData.productName;
        }
        /* istanbul ignore else */
        if (paypalData.hasOwnProperty('tier')) {
            tier = paypalData.tier;
        }
        /* istanbul ignore else */
        if (paypalData.hasOwnProperty('hasPaypalPayment')) {
            hasPaypalPayment = paypalData.hasPaypalPayment;
        }
        /* istanbul ignore else */
        if (paypalData.hasOwnProperty('isCCRequiredTrial')) {
            isCCRequiredTrial = paypalData.isCCRequiredTrial;
        }
        /* istanbul ignore else */
        if (paypalData.hasOwnProperty('planIsTrial')) {
            planIsTrial = paypalData.planIsTrial;
        }
    }

    paypalData = {
        totalAmount: totalAmount,
        productCode: productCode,
        productName: productName,
        orderId: getLastOrderId(),
        tier: tier,
        hasPaypalPayment: hasPaypalPayment,
        isCCRequiredTrial: isCCRequiredTrial,
        planIsTrial: planIsTrial,
    };

    return paypalData;
};

export const getPageValueForCheckoutABTest = (url) => {
    let page = 'plans', checkoutPageUrl = APP_HASH_PATH.CHECKOUT_PAGE + CHECKOUT_PAGE_TYPE.CHECKOUT_SUMMARY;
    url = url || window.location.href;

    if (isInHub() === false) {
        page = 'website_' + page;
    } else {
        /* istanbul ignore else */
        if (url.indexOf(checkoutPageUrl) !== -1) {
            page = 'checkout_summary';
        }
        page = (shouldViewUpgrade() ? 'upgrade_' : 'website_') + page;
    }

    return page;
};


export const getBranchName = () => {
    let branchName = localStorage.getItem(LOCAL_BRANCH_NAME);
    /* istanbul ignore else */
    if (branchName === null) {
        const scripts = document.getElementsByTagName('script');

        for (let i = 0; i < scripts.length; i++) {
            let src = scripts[i].getAttribute('src');
            if (src && src.indexOf("/branch/") > 0) {
                src = src.substr(src.indexOf("/branch") + 1, src.length - 1);
                src = src.substr(src.indexOf("/") + 1, src.length - 1);
                branchName = src.substr(0, src.indexOf("/"));
                break;
            }
        }
        if (branchName === null) {
            // branch name is still NULL, we set a default value
            branchName = 'local-dev';
        }
        localStorage.setItem(LOCAL_BRANCH_NAME, branchName);
    }

    return branchName;
};

// Shuffle array
export const  shuffleArray = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

export const isExtensionFlow = () => {
    return (window.location.href.indexOf('flow=extension') !== -1 || window.location.href.indexOf('origin=rce') !== -1);
}

export const getChromeExtensionLocationParamValue = () => {
    const flowParams = URLHelper.getUrlParamValueFromWindowObj(window.location, 'flowParameters');
    let flowParamObj;
    if (flowParams && flowParams && flowParams.hasOwnProperty('location')) {
        flowParamObj = JSON.parse(atob(flowParams));
    }

    if (flowParamObj && flowParamObj.hasOwnProperty('location')) {
        return flowParamObj.location;
    } else {
        return CHROME_RECORDER_APP;
    }
}

export const getChromeExtensionId = () => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return 'deidfdfkmabijngekobgdenaaohfoofc';
        case 'production':
            return 'deidfdfkmabijngekobgdenaaohfoofc';
        default:
            return 'deidfdfkmabijngekobgdenaaohfoofc';
    }
}

export const setRegisterPurchaseTrackingAmount = (amount) => {
    sessionStorage.setItem(LS_REGISTERED_PURCHASE_AMOUNT, amount);
}

export const getRegisteredPurchaseTrackingAmount = () => {
    const amount = sessionStorage.getItem(LS_REGISTERED_PURCHASE_AMOUNT);
    if (typeof amount !== 'undefined' && amount !== null) {
        return parseFloat(amount);
    }

    return null;
}

export const clearRegisteredPurchaseTrackingAmount = () => {
    sessionStorage.removeItem(LS_REGISTERED_PURCHASE_AMOUNT);
}

export const trimString = (input) => {
    if(typeof String.prototype.trim !== 'function') {
        return input.replace(/^\s+|\s+$/g, '');
    } else {
        return input.trim();
    }
};

export const json = {
    /**
     * Converts the given data structure to a JSON string.
     * Argument: arr - The data structure that must be converted to JSON
     * Example: let json_string = encode(['e', {pluribus: 'unum'}]);
     *          let json = encode({"success":"Sweet","failure":false,"empty_array":[],"numbers":[1,2,3],"info":{"name":"Binny","site":"http:\/\/www.openjs.com\/"}});
     * http://www.openjs.com/scripts/data/json_encode.php
     */
    encode: function (arr) {
        let parts = [];
        let is_list = (Object.prototype.toString.apply(arr) === '[object Array]');

        // eslint-disable-next-line no-unused-vars
        for(let key in arr) {
            let value = arr[key];
            if(typeof value == "object") { //Custom handling for arrays
                if(is_list) parts.push(this.encode(value)); /* :RECURSION: */
                else parts.push('"' + key + '":' + this.encode(value)); /* :RECURSION: */
                //else parts[key] = encode(value); /* :RECURSION: */

            } else {
                let str = "",
                    escapedKey = key.replace(/[\\"']/g, "\\$&");
                if(!is_list) str = '"' + escapedKey + '":';

                //Custom handling for multiple data types
                if(typeof value == "number") str += value; //Numbers
                else if(value === false) str += 'false'; //The booleans
                else if(value === true) str += 'true';
                else {
                    let escapedValue = value.replace(/[\\"']/g, "\\$&");
                    str += '"' + escapedValue + '"';
                } //All other things
                // :TODO: Is there any more datatype we should be in the lookout for? (Functions?)

                parts.push(str);
            }
        }
        let json = parts.join(",");

        if(is_list) return '[' + json + ']';//Return numerical JSON
        return '{' + json + '}';//Return associative JSON
    },
    decode: (function () {
        let number
            = '(?:-?\\b(?:0|[1-9][0-9]*)(?:\\.[0-9]+)?(?:[eE][+-]?[0-9]+)?\\b)';
        let oneChar = '(?:[^\\0-\\x08\\x0a-\\x1f"\\\\]'
            + '|\\\\(?:["/\\\\bfnrt]|u[0-9A-Fa-f]{4}))';
        let string = '(?:"' + oneChar + '*")';

        // Will match a value in a well-formed JSON file.
        // If the input is not well-formed, may match strangely, but not in an unsafe
        // way.
        // Since this only matches value tokens, it does not match whitespace, colons,
        // or commas.
        let jsonToken = new RegExp(
            '(?:false|true|null|[\\{\\}\\[\\]]'
            + '|' + number
            + '|' + string
            + ')', 'g');

        // Matches escape sequences in a string literal
        let escapeSequence = new RegExp('\\\\(?:([^u])|u(.{4}))', 'g');

        // Decodes escape sequences in object literals
        let escapes = {
            '"': '"',
            '/': '/',
            '\\': '\\',
            'b': '\b',
            'f': '\f',
            'n': '\n',
            'r': '\r',
            't': '\t'
        };
        function unescapeOne(_, ch, hex) {
            return ch ? escapes[ch] : String.fromCharCode(parseInt(hex, 16));
        }

        // A non-falsy value that coerces to the empty string when used as a key.
        let EMPTY_STRING = '';
        let SLASH = '\\';

        // Constructor to use based on an open token.
        // let firstTokenCtors = { '{': Object, '[': Array };

        return function (json) {
            // Split into tokens
            let toks = json.match(jsonToken);
            // Construct the object to return
            let result;
            let tok = toks[0];
            if ('{' === tok) {
                result = {};
            } else if ('[' === tok) {
                result = [];
            } else {
                throw new Error(tok);
            }

            // If undefined, the key in an object key/value record to use for the next
            // value parsed.
            let key;
            // Loop over remaining tokens maintaining a stack of uncompleted objects and
            // arrays.
            let stack = [result];
            for (let i = 1, n = toks.length; i < n; ++i) {
                tok = toks[i];

                let cont;
                switch (tok.charCodeAt(0)) {
                    default:  // sign or digit
                        cont = stack[0];
                        cont[key || cont.length] = +(tok);
                        key = void 0;
                        break;
                    case 0x22:  // '"'
                        tok = tok.substring(1, tok.length - 1);
                        if (tok.indexOf(SLASH) !== -1) {
                            tok = tok.replace(escapeSequence, unescapeOne);
                        }
                        cont = stack[0];
                        if (!key) {
                            if (cont instanceof Array) {
                                key = cont.length;
                            } else {
                                key = tok || EMPTY_STRING;  // Use as key for next value seen.
                                break;
                            }
                        }
                        cont[key] = tok;
                        key = void 0;
                        break;
                    case 0x5b:  // '['
                        cont = stack[0];
                        stack.unshift(cont[key || cont.length] = []);
                        key = void 0;
                        break;
                    case 0x5d:  // ']'
                        stack.shift();
                        break;
                    case 0x66:  // 'f'
                        cont = stack[0];
                        cont[key || cont.length] = false;
                        key = void 0;
                        break;
                    case 0x6e:  // 'n'
                        cont = stack[0];
                        cont[key || cont.length] = null;
                        key = void 0;
                        break;
                    case 0x74:  // 't'
                        cont = stack[0];
                        cont[key || cont.length] = true;
                        key = void 0;
                        break;
                    case 0x7b:  // '{'
                        cont = stack[0];
                        stack.unshift(cont[key || cont.length] = {});
                        key = void 0;
                        break;
                    case 0x7d:  // '}'
                        stack.shift();
                        break;
                }
            }
            // Fail if we've got an uncompleted object.
            if (stack.length) { throw new Error(); }
            return result;
        };
    })()
};

export const getTemplateRedirectUrl = () => {
    const templateSignIn = sessionStorage.getItem("templatesSignupSignIn");
    const templateId = sessionStorage.getItem("lastVisitedTemplateId");
    
    if (templateSignIn === "set" && templateId) {
        return APP_BROWSER_ROUTES.TEMPLATE_REDIRECT + "?templateId=" + templateId + "&avoidRedirecting=true";
    }

    return false;
}

export const getSegmentationInfoRedirectUrl = () => {
    const segmentationInfo = getLocalStorageSegmentationInfo();
    if (!segmentationInfo) {
        return APP_BROWSER_ROUTES.SEGMENTATION_INFO_REDIRECT + "?avoidRedirecting=true";
    }

    return false;
}

export const sanitizeInput = (string) => {
    let map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
    };
    let reg = /[&<>"'/]/ig;
    return string.replace(reg, function(match) {
        return map[match];
    });
}

export const getIndicativeCookie = () =>  {
    if (!!document.cookie) {
        let cookie_ar = document.cookie.split(';');

        for(let i = 0 ; i < cookie_ar.length ; i++) {
            let cookie = trimString(cookie_ar[i]);
            if(!!cookie && cookie.indexOf(INDICATIVE_COOKIE_NAME) === 0){
                let obj = json.decode(decodeURIComponent(cookie.substring(INDICATIVE_COOKIE_NAME.length + 2, cookie.length - 1))) || {};
                return obj || {};
            }
        }
    }

    return {};
};
export const getDefaultUniqueID = () => {
    let cookie = getIndicativeCookie();

    return cookie.hasOwnProperty('defaultUniqueID') ? cookie.defaultUniqueID : '';
};

export const cartLoadedFromWebApp = () => {
    if (!isExtensionFlow() && loadedInIframe()) {
        return window.self !== window.parent && parentIsSameOrigin() && window.parent.hasOwnProperty('UserSession');
    }

    return false;
}

export const getAppVersion = () => {
    if (isExtensionFlow()) {
        return CHROME_RECORDER_APP;
    } else if (cartLoadedFromWebApp()) {
        return window.parent.UserSession.getAppVersion();
    } else {
        return "web_" + APP_VERSION;
    }
}

export const getWebAppVersionNumber = () => {
    if (cartLoadedFromWebApp()) {
        return window.parent.UserSession.getAppVersionNumber();
    } else {
        const appVersion = getAppVersionNumber();

        return appVersion !== "-1" ? appVersion : HUB_VERSION;
    }
}

export const getAxiosConfig = () => {
    return {
        baseURL: getApplicationBaseUrl() || 'http://localhost:3000',
        timeout: 30000,
        headers: {
            "wev-app-version": getAppVersion() + "/" + getWebAppVersionNumber(),
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    }
}

export const getAxiosLoginConfig = () => {
    let axiosConfig = getAxiosConfig();

    axiosConfig.headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
    axiosConfig.headers["Accept"] = "application/json, text/javascript, */*; q=0.01";

    return axiosConfig;
}

export const addSelfReferenceCanonical = () => {
    const canonical = !!document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    canonical.setAttribute('href', window.location.protocol + '//' + window.location.host + window.location.pathname);
    document.head.appendChild(canonical);
}