import React from "react";
import {WEVIDEO_PLANS} from "./AppGlobal";

export const EDUCATION_DEFAULT_SEATS = 30;
export const EDUCATION_MIN_SEATS = 10;
export const EDUCATION_MAX_ECOMMERCE_SEATS = 999;
export const EDUCATION_MAX_SEATS = 1500;
export const EDUCATION_MAX_SLIDER_SEATS = 1500;
export const EDUCATION_BASE_PRICE = 299;
export const EDUCATION_DEFAULT_PRICE_PER_SEAT = EDUCATION_BASE_PRICE / EDUCATION_DEFAULT_SEATS;

export const PLAN_DEFAULT_CONTEXT = '';
export const PLAN_HUB_CONTEXT = 'hub';

export const PLAN_STAGE = {
    PLAN_SELECTION: "PLAN_SELECTION",
    CONFIRMATION: "CONFIRMATION",
    COMPLETED: "COMPLETED",
    ADDONS: "ADDONS",
    ACTIVATION: "ACTIVATION"
};

export const PLAN_SELECTION = {
    EDU_TRIAL: 'eduTrial',
    BUSINESS_TRIAL: 'businessTrial',
    PROFESSIONAL_TRIAL: 'professionalTrial',
};

export const EDUCATION_PLAN_TRIAL_ITEMS = [
    "Green screen",
    "Secure environment",
    "Screencasting",
    "Classroom management",
    "Voice recording",
    "Project collaboration",
    "Essentials media library",
];

export const PLAN_SELECTED_TIER_PERSONAL = 'personal';
export const PLAN_SELECTED_TIER_BUSINESS = 'business';
export const PLAN_SELECTED_TIER_EDUCATION = 'education';
export const PLAN_SELECTED_TIER_PROFESSIONAL = 'professional';
export const PLAN_SELECTED_TIER_UNLIMITED = 'unlimited';
export const PLAN_SELECTED_TIER_PLAYPOSIT = 'playposit';
export const ALL_ALLOWED_TIERS = [PLAN_SELECTED_TIER_PERSONAL, PLAN_SELECTED_TIER_BUSINESS, PLAN_SELECTED_TIER_EDUCATION, PLAN_SELECTED_TIER_PROFESSIONAL, PLAN_SELECTED_TIER_UNLIMITED, PLAN_SELECTED_TIER_PLAYPOSIT];

export const BUY_CONTEXT = "buy";
export const BUY_CONTEXT_TRIAL = "trial";
export const BUY_CONTEXT_FREE = "free";
export const ALLOWED_BUY_CONTEXTS = [BUY_CONTEXT, BUY_CONTEXT_TRIAL, BUY_CONTEXT_FREE];

export const PLAN_SELECTED_VERTICAL_K12 = 'k12';

export const PRODUCTS_TERM_MODE = {
    MONTHLY_MODE: 'monthly',
    ANNUAL_MODE: 'annual',
};

export const PLANS_FAQ = {
    bellows: [
        {
            header: "How is a Free account different from a paid account?",
            content:<div>
                Free accounts are limited to five minutes of published video time a month and 1 GB of cloud storage and 480p resolution.  All users of paid WeVideo accounts enjoy the ability to publish videos without the WeVideo watermark or bumper, access to a much larger music library, better resolutions and advanced editing features like screen recording and green screen.
            </div>,
            forFree: true
        },
        {
            header:"How do I upgrade my existing account?",
            content:<div>
                Upgrading is easy! Just click on the upgrade button located on the My Account tab and then follow the steps that appear.
            </div>
        },
        {
            header:"What's included in a WeVideo account?",
            content:<div>
                Every WeVideo account, including free accounts, features a remarkable suite of creative features including Picture-in-Picture and the ability to use your own music, all at no charge. When you upgrade to a premium plan, you gain significantly more publishing time and cloud storage, support for higher resolution video, as well as access to a huge list of additional features such as green screen, slow motion controls, broadcast-quality motion titles, screen recording, and an expanded library of license-free music. <a className="second-primary" href="/sign-up" target="_BLANK" rel="noopener noreferrer">More details are available here</a>.
            </div>
        },
        {
            header:"Can I create videos in HD or 4K?",
            content:<div>
                Absolutely! All WeVideo users can edit up to 4K footage in WeVideo. In fact, you can import almost any type of footage and freely mix and match with the more than 600 audio, video, and graphics media formats supported by WeVideo. <a className="second-primary" href="//wevideo.zendesk.com/hc/en-us/articles/210290638-What-type-of-images-videos-are-optimal-for-WeVideo-What-file-formats-are-supported-" target="_BLANK" rel="noopener noreferrer">See the complete list here</a>. Unlimited subscribers can publish their videos in 1080p and 4K Ultra HD at no additional charge. Users with a Flex or Power account can purchase 1080p and 4K publish upgrades on an as-needed basis.
            </div>
        },
        {
            header:"What is publish time?",
            content:<div>
                When you are done editing your video, the next step is to “publish—our way of saying combine all the elements of your movie into a final MP4 file—at the resolution you want, so that you can share it anywhere. Publish time is the duration of your published videos. Look to the <a className="second-primary" href="/sign-up?compare">plan comparison chart</a> on total available publish time per plan. For example, 1 hour per month will allow you to publish 20 videos that are each three minutes long, 12 videos that are each five minutes long, or any other combination that adds up to your total available publish time.  Publish time is not affected by the amount of time you can spend working in the editor and/or the duration of your media assets.  More information is <a className="second-primary" href="//wevideo.zendesk.com/hc/en-us/articles/211013497-What-is-used-time-and-used-storage-Capacity-" target="_BLANK" rel="noopener noreferrer">available here</a>.
            </div>
        },
        {
            header:"What is cloud storage? Can I free up storage space? How do I get more storage?",
            content:<div>
                You can easily keep track of your available storage from the My Account page when you are logged in to WeVideo. The media files uploaded to your account—e.g. video, audio, photos, etc.—take up storage space. Published videos also take up storage space. You can easily reclaim storage space by deleting published videos and media assets that you are no longer using. In addition, more storage is available by upgrading your account.
            </div>
        },
        {
            header:"How do I cancel my subscription? What happens when I cancel?",
            content:<div>
                Subscriptions automatically renew based on the term you selected when you created your account, either monthly or annually, until cancelled. You can cancel your subscription at any time by clicking on the “Cancel Subscription” button on the WeVideo Account tab. When you cancel your subscription, you will retain full access to your account and its full features until the end of your paid term.
            </div>
        },
        {
            header:"Can I rollover unused publish time?",
            content:<div>
                No. The time for most WeVideo plans gets reset every month. The exceptions to this are Flex plans which are limited to 20 minutes total cumulative publish time used anytime during the 12 month duration of the plan.
            </div>
        },
        {
            header:"Can I add my videos to social media, video sharing and/or file sharing sites?",
            content:<div>
                WeVideo is all about sharing! When you publish your video, WeVideo can simultaneously and automatically send it to your preferred social media and sharing sites such as YouTube and Facebook. You can also download your video for local viewing and sharing.
            </div>
        },
        {
            header:"Can I use my videos created on WeVideo for commercial purposes, including the music?",
            content:<div>
                Absolutely! Your videos are yours to use. Just remember that copyright and trademark laws must still be followed. All music provided from the WeVideo music library is licensed for the free use of our subscribers.
            </div>
        },
        {
            header:"Can I save my brand’s logo, color and fonts?",
            content:<div>
                Yes you can. WeVideo users on Professional and Business plans can upload their brand elements to be available at all times on WeVideo. Users can upload their brand logo to use as a watermark, select colors via the color grid or pick the exact color by entering the hex code and select up to three specific fonts from the WeVideo library that align with your brand.
            </div>
        },
        {
            header:"How can I edit videos for free?",
            content:<div>
                Start making videos for free by downloading WeVideo from the App Store or Google Play Store. Want more features and publish time? Upgrade to one of several paid plans that fits your video needs.
            </div>
        }
    ],
    footer: <div className="footer">Still have questions? Visit our <a className="second-primary" href="/support" target="_BLANK" rel="noopener noreferrer">support center</a>.</div>
};

export const PLANS_EDU_FAQ = {
    bellows: [
        {
            header:"How is WeVideo for Schools different from the individual and business plans?",
            content:<div>
                WeVideo for Schools was designed specifically for an education environment and schools, teachers and students. The account is managed by school technology or teaching staff and purpose-built to provide students an environment for creation and collaboration that meets strict privacy and security regulations. More than just a video editor, WeVideo sparks creativity with tools for collaboration, green screen, podcasting, simultaneous screen and webcam recording, and much more. Students learn through revision and iteration with peer review tools like inline comments, combined edits which allows students to collaborate on projects.
            </div>
        },
        {
            header:"How many accounts do I need for my class, school or district?",
            content:<div>
                WeVideo for Schools is offered as a multi-user license that is priced per user. When you purchase one license, it provides a bundle of "accounts" (also called "seats"). Therefore you purchase a license based on the number of users you would like to accommodate. Every teacher, student, or administrator using the system will need their own login and should be considered a unique user. Volume discounts are available for school and district purchases, and those discounts increase with the number of users included in an order. The minimum purchase is a 30 seat license. Although there is a minimum number of seats required for an EDU purchase, this plan is designed to accommodate any number of users up to 30.
            </div>
        },
        {
            header:"What is a seat and is one required for every user?",
            content:<div>
                Everyone using WeVideo for Schools (eg. teachers, students and technology staff) requires their own account login. Each login is considered a seat and is a unique account (or user). These seats can be allocated to a user and reallocated to a new person at no additional cost (for example at the end of a school year or when a student leaves a district). <a className="second-primary" href="https://wevideo.zendesk.com/hc/en-us/articles/214581497-Removing-or-deleting-a-member" target="_blank" rel="noopener noreferrer">Learn more</a>.
            </div>
        },
        {
            header:"Can we re-assign seats?",
            content:<div>
                Yes. WeVideo for Schools allows your organization to re-assign seats. This is often done at the start of the year to allow incoming students to take seats that have opened up as last year's students have graduated, changed schools or classes. <a className="second-primary" href="https://wevideo.zendesk.com/hc/en-us/articles/214581497-Removing-or-deleting-a-member" target="_blank" rel="noopener noreferrer">Learn more</a>.
            </div>
        },
        {
            header:"Can users share logins?",
            content:<div>
                No. Every WeVideo for Schools user is a member (or seat) in the account and needs to have their own unique login for their privacy and protection. Login sharing is also a violation of our <a className="second-primary" href="/enterprise-terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>. The same rules apply to WeVideo individual accounts. Every user is required to have their own unique login/seat.
            </div>
        },
        {
            header:"How do you handle student privacy?",
            content:<div>
                We are signatories of the well-known and rigorous <a className="second-primary" href="https://studentprivacypledge.org/" target="_blank" rel="noopener noreferrer">Student Privacy Pledge</a>. Student data is not collected, stored or shared. We are fully compliant with laws protecting the privacy of minors using online services, including COPPA and FERPA federal laws and many state laws. A WeVideo for Schools account is managed by school staff, and allows the account administrator to control student membership, sharing and activities. Our Privacy policy is <a className="second-primary" href="/edu-privacy" target="_blank" rel="noopener noreferrer">here</a>. Our Terms of Use agreement is <a className="second-primary" href="/enterprise-terms-of-use" target="_blank"  rel="noopener noreferrer">here</a>.
            </div>
        },
        {
            header:"Do you offer support and/or training?",
            content:<div>
                WeVideo is fully committed to the success of its education users. Our responsive, expert education support staff is available to all paid WeVideo for Schools account holders. Our online <a className="second-primary" href="/education-resources" target="_blank" rel="noopener noreferrer">Resources for Educators</a> provides ready-to-use lesson plans, tutorials, tips and tricks. We also provide <a className="second-primary" href="/academy" target="_blank" rel="noopener noreferrer">WeVideo Academy</a>, a fully stocked web resource full of easy to follow videos on everything from getting started to mastering advanced features. We also offer <a className="second-primary" href="http://pages.wevideo.com/pd-offering-aug-2016" target="_blank"  rel="noopener noreferrer">professional development services</a> directly and through some amazing partners. Contact education sales for <a className="second-primary" href="/sales/contactus" target="_blank"  rel="noopener noreferrer">more info</a>.
            </div>
        },
        {
            header:"Can we use Chromebook, laptop, desktop, or mobile device to edit on WeVideo?",
            content:<div>
                Absolutely! You can edit complex videos on Chromebook, PC, or Mac and seamlessly continue projects between all of these so students are never bound to a single device. For on-the-go creation, you can use our mobile apps on any iOS and Android device. You can read about our system requirements <a className="second-primary" href="https://wevideo.zendesk.com/hc/en-us/articles/210290298-What-browser-settings-are-best-for-WeVideo-System-Requirements-" target="_blank" rel="noopener noreferrer">here</a>.
            </div>
        },
        {
            header:"Can I just get pricing? I'm not ready to buy",
            content:<div>
                Yes. Visit our <a className="second-primary" href="/sign-up#tab_education" target="_blank" rel="noopener noreferrer">pricing for education</a> page and select the number of seats that you need. Click on “Get a quote” and follow the steps. After you complete the form, we will instantly generate a quote for you. When you are ready to purchase, you can retrieve your quote and convert that to an order. Have more questions? <a className="second-primary" href="https://pages.wevideo.com/contact-education-specialist" target="_blank" rel="noopener noreferrer">Contact WeVideo Education Sales</a>.
            </div>
        },
        {
            header:"How does a school purchase or get a quote from WeVideo?",
            content:<div>
                A school, school district, university or other educational institution can purchase WeVideo for Schools with a purchase order or with a credit card. The price and quote generator on <a className="second-primary" href="/sign-up#tab_education" target="_blank" rel="noopener noreferrer">this page</a> will take you through the steps to create a quote or pro forma invoice which you can share, modify or download. You will also find a link to our W9 and payment options. If you need assistance with a purchase, please reach out to us <a className="second-primary" href="/sales/contactus" target="_blank" rel="noopener noreferrer">here</a>.
            </div>
        },
        {
            header:"I am not in charge of payment. What are my next steps?",
            content:<div>
                Visit our <a className="second-primary" href="/sign-up#tab_education" target="_blank" rel="noopener noreferrer">pricing for education</a> page, click on “Get a quote”, enter the number of seats you need and follow the steps. After you complete the information to get a quote, we will instantaneously generate a link which you can share with your decision makers and your procurement or business office so that they can complete the payment. You can also download the quote, pro-forma invoice and W9 to share via email or in print. Questions? <a className="second-primary" href="https://pages.wevideo.com/contact-education-specialist" target="_blank" rel="noopener noreferrer">Contact WeVideo Education Sales</a>.
            </div>
        },
        {
            header:"How do I renew my WeVideo for Schools plan?",
            content:<div>
                We're here to help you. Contact <a className="second-primary" href="/sales/contactus" target="_blank" rel="noopener noreferrer">WeVideo Education Sales</a> and include the name and email address of the school staff member to whom the WeVideo for Schools account is registered.
            </div>
        },
        {
            header:"How do I renew my WeVideo for Teachers plan?",
            content:<div>
                It will autorenew when your annual subscription is over. You may cancel before your subscription is over, if you do not want it to renew.
            </div>
        },
        {
            header:"What are the monthly publishing limitations?",
            content:<div>
                The WeVideo for Teachers plan allows teachers to publish up to 30 hours of video per month. The WeVideo for Schools plan allows every user to publish on average an hour of video per month, which is pooled across all members (i.e. 30 seats will share 30 hours per month). Published video refers to a video edit that is complete and has been rendered into a final video. There is no limit on how long users can work in the application. If you think your school or district will exceed the publishing limit, <a className="second-primary" href="https://pages.wevideo.com/contact-education-specialist" target="_blank" rel="noopener noreferrer">contact WeVideo Education Sales</a> for assistance.
            </div>
        },
        {
            header:"Does WeVideo for Schools work with G Suite for Education or Office 365?",
            content:<div>
                Yes. WeVideo for Schools has built-in domain level sync with Office 365 and G-Suite. There is additional integration with Google Drive, Google Classroom, and Microsoft OneDrive, with more to come.
            </div>
        },
        {
            header:"Do you have monthly plans?",
            content:<div>
                Yes, but they are not intended for education groups. We know our school users might only do one or two projects per year, therefore our annual plans for education are heavily discounted to accommodate these requirements. Our monthly plans do not offer WeVideo's significant educational discount. It is also important to note that WeVideo for Schools includes significant, purpose-built features for schools. These features are not present in individual WeVideo plans. These capabilities include stringent adherence to privacy and protection policies for students, assessment tools for teachers, and robust administration and management capabilities that scale to meet classroom, campus-wide and entire district requirements.
            </div>
        },
        {
            header:"How does WeVideo comply with the General Data Protection Regulation (GDPR) legislation?",
            content:<div>
                WeVideo is fully compliant with GDPR as of May 25, 2018. We currently collect the following personally identifiable information - first name, last name, and email. The data is stored and accessed securely during the term of the contract. All data access is through secure SSL protocols. WeVideo maintains strict access control to ensure data is only accessible by users who have rights to it. On contract termination, all user data is securely returned and destroyed as directed by the user. WeVideo uses user data only for the purpose of fulfilling its duties under the agreement and does not share such data with or disclose it to any third party, except as required by law. WeVideo certifies that there has never been an instance where there has been a data breach or failure to comply with the data security agreement with a customer. For full details on WeVideo’s GDPR compliance, read our <a className="second-primary" href="/eduprivacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
            </div>
        },
        {
            header:"What are the cloud storage limits, publishing limits, and export resolution?",
            content:<div>
                All education plans include unlimited creation time in the account. WeVideo for Schools accounts also offer ample publish time and storage. For a 30-seat account, there is 30 hours/month of publishing time and 150 GB of storage shared amongst the users. The publish time and storage increases with additional seats. For the WeVideo for Teachers account, there is 30 hours/month of publishing time and 50 GB of storage. WeVideo for Schools and WeVideo for Teacher plans allows for videos to export in Standard HD.
            </div>
        },
    ]
};

export const EDUCATION_PLANS_CARDS_V2 = [
    {
        "type": "buy-teacher-plan",
        "title": "Single Teacher",
        "description": "Single-user plan specifically made for K-12 educators",
        "pricePreText": "",
        "priceText": "89/year",
        "smallDesc": "",
        "buttonText": "Purchase online",
        "subButtonMode": "text",
        "subButtonType": "",
        "subButtonText": <span className="eduPlansCardFootNote"><sup>*</sup>Valid K-12 educator email required</span>,
        "productCode": WEVIDEO_PLANS.EDUCATION_TEACHER_PLAN_Y
    },
    {
        "type": "trial",
        "title": "Classroom Trial",
        "description": "Give the full WeVideo experience a try with your students!",
        "pricePreText": "",
        "priceText": "FREE for 30 days",
        "smallDesc": "",
        "buttonText": "TRY IT TODAY!",
        "subButtonMode": "text",
        "subButtonType": "",
        "subButtonText": <span className="eduPlansCardFootNote"><sup>*</sup>Valid K-12 educator email required</span>,
        "productCode": WEVIDEO_PLANS.EDUCATION_TRIAL
    },
    {
        "type": "quote",
        "title": "Classroom",
        "description": "Single classroom, club, or for a group of users",
        "pricePreText": <span className="priceTextColor">Starts at</span>,
        "priceText": "299/year",
        "smallDesc": "",
        "buttonText": "Get a quote",
        "subButtonMode": "link",
        "subButtonType": "purchase-online",
        "subButtonText": "PURCHASE ONLINE NOW",
        "productCode": WEVIDEO_PLANS.EDUCATION_K12_PLAN_Y
    },
    {
        "type": "contact-sales",
        "title": "School or District",
        "description": "Per user, or per building pricing for grade, school or district implementations",
        "pricePreText": "",
        "priceText": "Custom Pricing",
        "smallDesc": "",
        "buttonText": "Contact Sales",
        "subButtonMode": "text",
        "subButtonType": "",
        "subButtonText": "",
        "productCode": false
    }
];

export const EDUCATION_PLANS_INCLUDES = [
    {
        title: "Creative tools that teachers and students love:",
        content: [
            {
                title: "Create with ease",
                description: "WeVideo’s interface makes it easy for teachers and students to get started creating.",
            },
            {
                title: "Royalty free media library",
                description: <span>Unlimited use of WeVideo Essentials&trade;, a curated library of over 1 million stock video, image and audio files.</span>,
            },
            {
                title: "Quick screen recording",
                description: "Easily record your screen and webcam in the WeVideo dashboard or our built-in Chrome extension.",
            },
            {
                title: "Easily create podcasts",
                description: "Students can go beyond video with voiceover and export audio-only files.",
            },
            {
                title: "Use green screen",
                description: "Take videos anywhere in the world (and space!) with this special effect.",
            },
            {
                title: "Collaboration",
                description: "Students can collaborate in real time on videos, podcasts, slideshows and more.",
            },
        ],
        extraCssClass: "leftSidePlanCardWrapper"
    },
    {
        title: "Features schools and districts trust and value:",
        content: [
            {
                title: "Works with all devices",
                description: "Compatible with Chromebooks, Apple, Windows and mobile devices (Android and iOS).",
            },
            {
                title: "COPPA/FERPA compliant",
                description: "WeVideo ensures a safe cloud environment for student content and collaboration.",
            },
            {
                title: "No software to install or maintain",
                description: "Everything is created, shared and stored on the WeVideo cloud.",
            },
            {
                title: "Implementation support and PD workshops",
                description: "Flexible professional development options to suit every educator.",
            },
            {
                title: "Flexible licensing",
                description: "Seats can be transferred as students leave and enter a class/district.",
            },
            {
                title: "Accessible through LMS",
                description: "Projects can be exported to Google Classroom, Schoology, Canvas and ClassLink.",
            },
        ],
        extraCssClass: "rightSidePlanCardWrapper"
    },
];

export const PERSONAL_PLANS_CARD_ITEMS = {
    'wv_single_1m': {
        'promo': false,
        'items': ['Publish 30 minutes/mo.', '20 GB storage', '720p HD resolution', 'Templates & motion titles'],
        'subtitle': false
    },
    'wv_single_12m': {
        'promo': false,
        'items': ['Publish 30 minutes/mo.', '20 GB storage', '720p HD resolution', 'Templates & motion titles'],
        'subtitle': false
    },
    'wv_unloct17_1m': {
        'promo': 'MOST POPULAR',
        'items': ['Unlimited publish time', 'Unlimited storage', '4K Ultra HD resolution'],
        'subtitle': 'Everything in <strong>Power</strong>'
    },
    'wv_unloct17_12m': {
        'promo': 'MOST POPULAR',
        'items': ['Unlimited publish time', 'Unlimited storage', '4K Ultra HD resolution'],
        'subtitle': 'Everything in <strong>Power</strong>'
    },
    'wv_bizindiv_1m': {
        'promo': 'BEST VALUE',
        'items': [
            {text: 'Unlimited stock usage', tooltip: 'Video, image and music clips', df: 'bizindiv'},
            {text: 'Brand management', tooltip: 'Add your logo and assets', df: 'bizindiv'},
            'Premium templates',
            '4K Ultra HD resolution',
        ],
        'subtitle': 'Everything in <strong>Unlimited</strong>'
    },
    'wv_bizindiv_12m': {
        'promo': 'BEST VALUE',
        'items': [
            {text: 'Unlimited stock usage', tooltip: 'Video, image and music clips', df: 'bizindiv'},
            {text: 'Brand management', tooltip: 'Add your logo and assets', df: 'bizindiv'},
            'Premium templates',
            '4K Ultra HD resolution',
        ],
        'subtitle': 'Everything in <strong>Unlimited</strong>'
    },
    'wv_bizoct17_1m': {
        'promo': false,
        'items': [
            {text: 'Team license (3 seats)', tooltip: 'Expandable license to add even more seats', df: 'bizoct17'},
            {text: 'Collaboration tools', tooltip: 'Work together anytime, anywhere', df: 'bizoct17'},
            'User management',
            'Create your own templates'
        ],
        'subtitle': 'Everything in <strong>Professional</strong>'
    },
    'wv_bizoct17_12m': {
        'promo': false,
        'items': [
            {text: 'Team license (3 seats)', tooltip: 'Expandable license to add even more seats', df: 'bizoct17'},
            {text: 'Collaboration tools', tooltip: 'Work together anytime, anywhere', df: 'bizoct17'},
            'User management',
            'Create your own templates'
        ],
        'subtitle': 'Everything in <strong>Professional</strong>'
    },
    'wv_individual_1m': {
        'promo': false,
        'promoBadge': 'Most popular',
        'items': [
            'Unlimited publishing',
            'Unlimited storage',
            'Brand management',
            'Premium templates',
            '4K Ultra HD resolution',
        ]
    },
    'wv_individual_12m': {
        'promo': false,
        'promoBadge': 'Most popular',
        'items': [
            'Unlimited publishing',
            'Unlimited storage',
            'Brand management',
            'Premium templates',
            '4K Ultra HD resolution',
        ]
    }
}

export const ENTERPRISE_PLAN_ITEMS = {
    'promo': false,
    'items': [
        'Flexible, team-based pricing',
        'Dedicated rendering space',
        'Personal customer support'
    ],
    'subtitle': 'Everything in <strong>Business</strong>'
}

export const PERSONAL_PLANS_SCHEMA = {
    "@context": "https://schema.org",
    "@type": "Product",
    "description": "",
    "name": "WeVideo Online video editor",
    "offers": [
        {
            "@type": "Offer",
            "availability": "https://schema.org/InStock",
            "name": "WeVideo Free Plan",
            "price": "0.00",
            "priceCurrency": "USD"
        },
        {
            "@type": "Offer",
            "availability": "https://schema.org/InStock",
            "name": "WeVideo Power Plan",
            "price": "4.99",
            "priceCurrency": "USD"
        },
        {
            "@type": "Offer",
            "availability": "https://schema.org/InStock",
            "name": "WeVideo Unlimited Plan",
            "price": "7.99",
            "priceCurrency": "USD"
        },
        {
            "@type": "Offer",
            "availability": "https://schema.org/InStock",
            "name": "WeVideo Professional Plan",
            "price": "19.99",
            "priceCurrency": "USD"
        },
        {
            "@type": "Offer",
            "availability": "https://schema.org/InStock",
            "name": "WeVideo Business Plan",
            "price": "36.99",
            "priceCurrency": "USD"
        }
    ],
    "subjectOf": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "How do I upgrade my existing account?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Upgrading is easy! Just click on the upgrade button located on the My Account tab and then follow the steps that appear."
            }
        },{
            "@type": "Question",
            "name": "What's included in a WeVideo account?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Every WeVideo account, including free accounts, features a remarkable suite of creative features including Picture-in-Picture and the ability to use your own music, all at no charge. When you upgrade to a premium plan, you gain significantly more publishing time and cloud storage, support for higher resolution video, as well as access to a huge list of additional features such as green screen, slow motion controls, broadcast-quality motion titles, screen recording, and an expanded library of license-free music. More details are available here."
            }
        },{
            "@type": "Question",
            "name": "Can I create videos in HD or 4K?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Absolutely! All WeVideo users can edit up to 4K footage in WeVideo. In fact, you can import almost any type of footage and freely mix and match with the more than 600 audio, video, and graphics media formats supported by WeVideo. See the complete list here. Unlimited subscribers can publish their videos in 1080p and 4K Ultra HD at no additional charge. Users with a Flex or Power account can purchase 1080p and 4K publish upgrades on an as-needed basis."
            }
        },{
            "@type": "Question",
            "name": "What is publish time?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "When you are done editing your video, the next step is to “publish—our way of saying combine all the elements of your movie into a final MP4 file—at the resolution you want, so that you can share it anywhere. Publish time is the duration of your published videos. Look to the plan comparison chart on total available publish time per plan. For example, 1 hour per month will allow you to publish 20 videos that are each three minutes long, 12 videos that are each five minutes long, or any other combination that adds up to your total available publish time. Publish time is not affected by the amount of time you can spend working in the editor and/or the duration of your media assets. More information is available here."
            }
        },{
            "@type": "Question",
            "name": "What is cloud storage? Can I free up storage space? How do I get more storage?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "You can easily keep track of your available storage from the My Account page when you are logged in to WeVideo. The media files uploaded to your account—e.g. video, audio, photos, etc.—take up storage space. Published videos also take up storage space. You can easily reclaim storage space by deleting published videos and media assets that you are no longer using. In addition, more storage is available by upgrading your account."
            }
        },{
            "@type": "Question",
            "name": "How do I cancel my subscription? What happens when I cancel?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Subscriptions automatically renew based on the term you selected when you created your account, either monthly or annually, until cancelled. You can cancel your subscription at any time by clicking on the “Cancel Subscription” button on the WeVideo Account tab. When you cancel your subscription, you will retain full access to your account and its full features until the end of your paid term."
            }
        },{
            "@type": "Question",
            "name": "Can I rollover unused publish time?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "No. The time for most WeVideo plans gets reset every month. The exceptions to this are Flex plans which are limited to 20 minutes total cumulative publish time used anytime during the 12 month duration of the plan."
            }
        },{
            "@type": "Question",
            "name": "Can I add my videos to social media, video sharing and/or file sharing sites?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "WeVideo is all about sharing! When you publish your video, WeVideo can simultaneously and automatically send it to your preferred social media and sharing sites such as YouTube and Facebook. You can also download your video for local viewing and sharing."
            }
        },{
            "@type": "Question",
            "name": "Can I use my videos created on WeVideo for commercial purposes, including the music?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Absolutely! Your videos are yours to use. Just remember that copyright and trademark laws must still be followed. All music provided from the WeVideo music library is licensed for the free use of our subscribers."
            }
        },{
            "@type": "Question",
            "name": "Can I save my brand’s logo, color and fonts?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes you can. WeVideo users on Professional and Business plans can upload their brand elements to be available at all times on WeVideo. Users can upload their brand logo to use as a watermark, select colors via the color grid or pick the exact color by entering the hex code and select up to three specific fonts from the WeVideo library that align with your brand."
            }
        }]
    }
}

export const EDUCATION_PLANS_SCHEMA = {
    "@context": "https://schema.org",
    "@type": "Product",
    "description": "",
    "name": "WeVideo Online video editor",
    "offers": [
        {
            "@type": "Offer",
            "availability": "https://schema.org/InStock",
            "name": "WeVideo Classrooom Trial",
            "price": "0.00",
            "priceCurrency": "USD"
        },
        {
            "@type": "Offer",
            "availability": "https://schema.org/InStock",
            "name": "WeVideo Teacher Plan",
            "price": "89.00",
            "priceCurrency": "USD"
        },
        {
            "@type": "Offer",
            "availability": "https://schema.org/InStock",
            "name": "WeVideo Classroom Plan",
            "price": "299.00",
            "priceCurrency": "USD"
        }],
    "subjectOf": {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "How is WeVideo for Schools different from the individual and business plans?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "WeVideo for Schools was designed specifically for an education environment and schools, teachers and students. The account is managed by school technology or teaching staff and purpose-built to provide students an environment for creation and collaboration that meets strict privacy and security regulations. More than just a video editor, WeVideo sparks creativity with tools for collaboration, green screen, podcasting, simultaneous screen and webcam recording, and much more. Students learn through revision and iteration with peer review tools like inline comments, combined edits which allows students to collaborate on projects."
            }
        },{
            "@type": "Question",
            "name": "How many accounts do I need for my class, school or district?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "WeVideo for Schools is offered as a multi-user license that is priced per user. When you purchase one license, it provides a bundle of accounts (also called seats). Therefore you purchase a license based on the number of users you would like to accommodate. Every teacher, student, or administrator using the system will need their own login and should be considered a unique user. Volume discounts are available for school and district purchases, and those discounts increase with the number of users included in an order. The minimum purchase is a 30 seat license. Although there is a minimum number of seats required for an EDU purchase, this plan is designed to accommodate any number of users up to 30."
            }
        },{
            "@type": "Question",
            "name": "What is a seat and is one required for every user?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Everyone using WeVideo for Schools (eg. teachers, students and technology staff) requires their own account login. Each login is considered a seat and is a unique account (or user). These seats can be allocated to a user and reallocated to a new person at no additional cost (for example at the end of a school year or when a student leaves a district). Learn more."
            }
        },{
            "@type": "Question",
            "name": "Can we re-assign seats?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes. WeVideo for Schools allows your organization to re-assign seats. This is often done at the start of the year to allow incoming students to take seats that have opened up as last year's students have graduated, changed schools or classes. Learn more."
            }
        },{
            "@type": "Question",
            "name": "Can users share logins?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "No. Every WeVideo for Schools user is a member (or seat) in the account and needs to have their own unique login for their privacy and protection. Login sharing is also a violation of our Terms of Use. The same rules apply to WeVideo individual accounts. Every user is required to have their own unique login/seat."
            }
        },{
            "@type": "Question",
            "name": "How do you handle student privacy?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "We are signatories of the well-known and rigorous Student Privacy Pledge. Student data is not collected, stored or shared. We are fully compliant with laws protecting the privacy of minors using online services, including COPPA and FERPA federal laws and many state laws. A WeVideo for Schools account is managed by school staff, and allows the account administrator to control student membership, sharing and activities. Our Privacy policy is here. Our Terms of Use agreement is here."
            }
        },{
            "@type": "Question",
            "name": "Do you offer support and/or training?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "WeVideo is fully committed to the success of its education users. Our responsive, expert education support staff is available to all paid WeVideo for Schools account holders. Our online Resources for Educators provides ready-to-use lesson plans, tutorials, tips and tricks. We also provide WeVideo Academy, a fully stocked web resource full of easy to follow videos on everything from getting started to mastering advanced features. We also offer professional development services directly and through some amazing partners. Contact education sales for more info."
            }
        },{
            "@type": "Question",
            "name": "Can we use Chromebook, laptop, desktop, or mobile device to edit on WeVideo?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Absolutely! You can edit complex videos on Chromebook, PC, or Mac and seamlessly continue projects between all of these so students are never bound to a single device. For on-the-go creation, you can use our mobile apps on any iOS and Android device. You can read about our system requirements here."
            }
        },{
            "@type": "Question",
            "name": "Can I just get pricing? I'm not ready to buy",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes. Visit our pricing for education page and select the number of seats that you need. Click on “Get a quote” and follow the steps. After you complete the form, we will instantly generate a quote for you. When you are ready to purchase, you can retrieve your quote and convert that to an order. Have more questions? Contact WeVideo Education Sales."
            }
        },{
            "@type": "Question",
            "name": "How does a school purchase or get a quote from WeVideo?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "A school, school district, university or other educational institution can purchase WeVideo for Schools with a purchase order or with a credit card. The price and quote generator on this page will take you through the steps to create a quote or pro forma invoice which you can share, modify or download. You will also find a link to our W9 and payment options. If you need assistance with a purchase, please reach out to us here."
            }
        },{
            "@type": "Question",
            "name": "I am not in charge of payment. What are my next steps?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Visit our pricing for education page, click on “Get a quote”, enter the number of seats you need and follow the steps. After you complete the information to get a quote, we will instantaneously generate a link which you can share with your decision makers and your procurement or business office so that they can complete the payment. You can also download the quote, pro-forma invoice and W9 to share via email or in print. Questions? Contact WeVideo Education Sales."
            }
        },{
            "@type": "Question",
            "name": "How do I renew my WeVideo for Schools plan?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "We're here to help you. Contact WeVideo Education Sales and include the name and email address of the school staff member to whom the WeVideo for Schools account is registered."
            }
        },{
            "@type": "Question",
            "name": "How do I renew my WeVideo for Teachers plan?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "It will autorenew when your annual subscription is over. You may cancel before your subscription is over, if you do not want it to renew."
            }
        },{
            "@type": "Question",
            "name": "What are the monthly publishing limitations?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "The WeVideo for Teachers plan allows teachers to publish up to 30 hours of video per month. The WeVideo for Schools plan allows every user to publish on average an hour of video per month, which is pooled across all members (i.e. 30 seats will share 30 hours per month). Published video refers to a video edit that is complete and has been rendered into a final video. There is no limit on how long users can work in the application. If you think your school or district will exceed the publishing limit, contact WeVideo Education Sales for assistance."
            }
        },{
            "@type": "Question",
            "name": "Does WeVideo for Schools work with G Suite for Education or Office 365?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes. WeVideo for Schools has built-in domain level sync with Office 365 and G-Suite. There is additional integration with Google Drive, Google Classroom, and Microsoft OneDrive, with more to come."
            }
        },{
            "@type": "Question",
            "name": "Do you have monthly plans?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, but they are not intended for education groups. We know our school users might only do one or two projects per year, therefore our annual plans for education are heavily discounted to accommodate these requirements. Our monthly plans do not offer WeVideo's significant educational discount. It is also important to note that WeVideo for Schools includes significant, purpose-built features for schools. These features are not present in individual WeVideo plans. These capabilities include stringent adherence to privacy and protection policies for students, assessment tools for teachers, and robust administration and management capabilities that scale to meet classroom, campus-wide and entire district requirements."
            }
        },{
            "@type": "Question",
            "name": "How does WeVideo comply with the General Data Protection Regulation (GDPR) legislation?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "WeVideo is fully compliant with GDPR as of May 25, 2018. We currently collect the following personally identifiable information - first name, last name, and email. The data is stored and accessed securely during the term of the contract. All data access is through secure SSL protocols. WeVideo maintains strict access control to ensure data is only accessible by users who have rights to it. On contract termination, all user data is securely returned and destroyed as directed by the user. WeVideo uses user data only for the purpose of fulfilling its duties under the agreement and does not share such data with or disclose it to any third party, except as required by law. WeVideo certifies that there has never been an instance where there has been a data breach or failure to comply with the data security agreement with a customer. For full details on WeVideo’s GDPR compliance, read our Privacy Policy."
            }
        },{
            "@type": "Question",
            "name": "What are the cloud storage limits, publishing limits, and export resolution?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "All education plans include unlimited creation time in the account. WeVideo for Schools accounts also offer ample publish time and storage. For a 30-seat account, there is 30 hours/month of publishing time and 150 GB of storage shared amongst the users. The publish time and storage increases with additional seats. For the WeVideo for Teachers account, there is 30 hours/month of publishing time and 50 GB of storage. WeVideo for Schools and WeVideo for Teacher plans allows for videos to export in Standard HD."
            }
        }]
    }
}

export const PLANS_GTM_EVENTS = {
    'wv_teacher_12m': 'education_teacher_plan_signup',
    'wv_k12_12m': 'education_classroom_plan_signup',
    'wv_single_1m': 'personal_power_m_plan_signup',
    'wv_single_12m': 'personal_power_y_plan_signup',
    'wv_unloct17_1m': 'personal_unlimited_m_plan_signup',
    'wv_unloct17_12m': 'personal_unlimited_y_plan_signup',
    'wv_bizindiv_trial': 'biz_professional_free_trial_signup',
    'wv_bizindiv_1m': 'biz_professional_m_plan_signup',
    'wv_bizindiv_12m': 'biz_professional_y_plan_signup',
    'wv_bizoct17_trial': 'biz_business_free_trial_signup',
    'wv_bizoct17_1m': 'biz_business_m_plan_signup',
    'wv_bizoct17_12m': 'biz_business_y_plan_signup',
    'wv_free': 'personal_free_plan_signup',
    'wv_individual_1m': 'biz_creator_m_plan_signup',
    'wv_individual_12m': 'biz_creator_y_plan_signup',
    'wv_edu_trial': 'education_free_trial_signup'
}

export const BULLETPOINTS_BY_PLAN = {
    'wv_free': {
        'title': 'Easy and stunning video in minutes with WeVideo!',
        'subtitle': 'With your new Free plan:',
        'bullets': ['Publish 5 min/month','Create with drag and drop templates','Record your screen, webcam, and voiceover','Make GIFs, videos, podcasts','Upgrade for no watermark and premium features!']
    },
    'wv_flex2': {
        'title': 'Stunning video in minutes with WeVideo!',
        'subtitle': 'WeVideo Flex includes:',
        'bullets': ['Up to 720p resolution','2 GB of cloud storage','20 minutes of publish time','Valid for 1 year','$29.99 one-time payment']
    },
    'wv_single_1m': {
        'title': 'Share memorable moments with WeVideo!',
        'subtitle': 'With your WeVideo Power plan:',
        'bullets': ['Publish up to 30 min/mo','Export square, vertical, or horizontal videos','Make GIFs, videos, and podcasts','Record your screen, webcam, and voiceover','Create with video and animated text templates','No WeVideo branding']
    },
    'wv_single_12m': {
        'title': 'Share memorable moments with WeVideo!',
        'subtitle': 'With your WeVideo Power plan:',
        'bullets': ['Publish up to 30 min/mo','Export square, vertical, or horizontal videos','Make GIFs, videos, and podcasts','Record your screen, webcam, and voiceover','Create with video and animated text templates','No WeVideo branding']
    },
    'wv_unloct17_1m': {
        'title': 'Share mesmerizing 4K video with WeVideo!',
        'subtitle': 'With your WeVideo Unlimited plan:',
        'bullets': ['Export square, vertical, or horizontal videos','Publish unlimited videos in up to 4K resolution','Make GIFs, videos, and podcasts','Record your screen, webcam, and voiceover','Create with video and animated text templates','No WeVideo branding']
    },
    'wv_unloct17_12m': {
        'title': 'Share mesmerizing 4K video with WeVideo!',
        'subtitle': 'With your WeVideo Unlimited plan:',
        'bullets': ['Export square, vertical, or horizontal videos','Publish unlimited videos in up to 4K resolution','Make GIFs, videos, and podcasts','Record your screen, webcam, and voiceover','Create with video and animated text templates','No WeVideo branding']
    },
    'wv_bizindiv_1m': {
        'title': 'Boost engagement with WeVideo!',
        'subtitle': 'With your WeVideo Professional plan:',
        'bullets': ['Export square, vertical, or horizontal videos','Publish unlimited videos in up to 4K resolution','Make GIFs, videos, podcasts','Access 1 million+ stock media clips','Create with video and animated text templates']
    },
    'wv_bizindiv_12m': {
        'title': 'Boost engagement with WeVideo!',
        'subtitle': 'With your WeVideo Professional plan:',
        'bullets': ['Export square, vertical, or horizontal videos','Publish unlimited videos in up to 4K resolution','Make GIFs, videos, podcasts','Access 1 million+ stock media clips','Create with video and animated text templates']
    },
    'wv_bizoct17_1m': {
        'title': 'Make an impact and boost sales with WeVideo!',
        'subtitle': 'With your WeVideo Business plan:',
        'bullets': ['Collaborate with your team (3 seats included)','Export square, vertical, or horizontal videos','Publish unlimited videos in up to 4K resolution','Make GIFs, videos, podcasts','Access 1 million+ stock media clips','Create with pre-made and branded templates']
    },
    'wv_bizoct17_12m': {
        'title': 'Make an impact and boost sales with WeVideo!',
        'subtitle': 'With your WeVideo Business plan:',
        'bullets': ['Collaborate with your team (3 seats included)','Export square, vertical, or horizontal videos','Publish unlimited videos in up to 4K resolution','Make GIFs, videos, podcasts','Access 1 million+ stock media clips','Create with pre-made and branded templates']
    },
    'wv_teacher_12m': {
        'title': 'Add a creative touch to your lesson plans with WeVideo!',
        'subtitle': 'Teacher plan includes:',
        'bullets': ['Education-safe stock video, images, and audio','Ample cloud storage and publish time','Templates created for education','720p HD resolution','Green screen','Screen recording']
    },
    'wv_k12_12m': {
        'title': 'Empower students to create with WeVideo!',
        'subtitle': 'With your WeVideo for Schools plan:',
        'bullets': ['Access royalty-free media library','Collaborate with students and teachers','Create with drag and drop templates','Record your screen, webcam, and voiceover','Make GIFs, videos, podcasts','Use green screen and creative features']
    },
    'wv_unlimited_trial': {
        'title': 'With your WeVideo Unlimited plan:',
        'subtitle': 'With your WeVideo Unlimited free',
        'bullets': ['Export square, vertical, or horizontal videos','Publish unlimited videos in up to 4K resolution','Make GIFs, videos, and podcasts','Record your screen, webcam, and voiceover','Create with video and animated text templates']
    },
    'wv_bizindiv_trial': {
        'title': 'With your WeVideo Professional plan:',
        'subtitle': 'With your WeVideo Professional free',
        'bullets': ['Export square, vertical, or horizontal videos','Publish unlimited videos in up to 4K resolution','Make GIFs, videos, podcasts','Access 1 million+ stock media clips','Create with video and animated text templates']
    },
    'wv_bizoct17_trial': {
        'title': 'With your WeVideo Business plan:',
        'subtitle': 'With your WeVideo Business free',
        'bullets': ['Collaborate with your team (3 seats included)','Export square, vertical, or horizontal videos','Publish unlimited videos in up to 4K resolution','Make GIFs, videos, podcasts','Access 1 million+ stock media clips','Create with pre-made and branded templates']
    },
    'wv_edu_trial': {
        'title': 'Try WeVideo free for 30 days',
        'subtitle': 'You and your students will be able to:',
        'bullets': ['Create videos, podcasts, GIFs and more!','Record your screen and webcam','Access our royalty-free media library','Experience a new level of creativity in the classroom']
    },
    'wv_individual_12m': {
        'title': 'Boost engagement with WeVideo!',
        'subtitle': 'With your WeVideo Creator plan:',
        'bullets': ['Export square, vertical, or horizontal videos','Publish unlimited videos in up to 4K resolution','Make GIFs, videos, podcasts','Access 1 million+ stock media clips','Create with video and animated text templates']
    },
    'wv_individual_1m': {
        'title': 'Boost engagement with WeVideo!',
        'subtitle': 'With your WeVideo Creator plan:',
        'bullets': ['Export square, vertical, or horizontal videos','Publish unlimited videos in up to 4K resolution','Make GIFs, videos, podcasts','Access 1 million+ stock media clips','Create with video and animated text templates']
    }
}

export const CUSTOMER_SIGNUP_QUOTES = {
    'education': [
        {
            'text': "Using WeVideo has transformed the way kids and staff GROW in their classrooms. Both the students and teachers have shifted from passive media consumers to active media creators.",
            'label': "Jennifer Eggert, Innovative Learning Coach at DuJardin Elementary School"
        },
        {
            'text': "The changes I’ve seen in students since they started using WeVideo is an excitement - there’s more engagement when they’re producing and creating something that demonstrates what they know and what they’ve learned.",
            'label': "Rayshell Fambrough, Educational Technology Specialist at Bakersfield City School District"
        },
        {
            'text': "The most meaningful moments I’ve observed with WeVideo are the students trying to figure out solutions to problems on their own or wanting to do something differently that’s outside the box.",
            'label': "Rhona McFadyen, Teacher at Dartmouth Middle School"
        }
    ],
    'personal': [
        {
            'text': "I’m blown away by what WeVideo is doing and the improvements I’ve seen over the past few years. While other video editors eat up my machines, WeVideo is so responsive. It doesn’t take us long at all to create videos using the tool.",
            'label': "David Tisdale, Marketing Director at Vx Group"
        },
        {
            'text': "I had never done any videography, film editing, nothing...I was able to create a very good end product using WeVideo, because it's very intuitive, very easy to use.",
            'label': "Amy Colony, Volunteer Coordinator at City of Pacific Grove"
        },
        {
            'text': "WeVideo is extremely easy to use. A simple video or complex tutorial can be created with the same effort.",
            'label': "Evan Mosier, Innovative Learning Coach at Sudlow Intermediate School"
        }
    ],
    'unlimited': [
        {
            'text': "I’m blown away by what WeVideo is doing and the improvements I’ve seen over the past few years. While other video editors eat up my machines, WeVideo is so responsive. It doesn’t take us long at all to create videos using the tool.",
            'label': "David Tisdale, Marketing Director at Vx Group"
        },
        {
            'text': "I had never done any videography, film editing, nothing...I was able to create a very good end product using WeVideo, because it's very intuitive, very easy to use.",
            'label': "Amy Colony, Volunteer Coordinator at City of Pacific Grove"
        },
        {
            'text': "WeVideo is extremely easy to use. A simple video or complex tutorial can be created with the same effort.",
            'label': "Evan Mosier, Innovative Learning Coach at Sudlow Intermediate School"
        }
    ],
    'professional': [
        {
            'text': "WeVideo gives us the ability to scale our video offerings without extensive staff training or design experience.",
            'label': "David Tisdale, Marketing Director at Vx Group"
        },
        {
            'text': "With WeVideo, I can easily create 30-second ads rather than paying someone else $25,000 to do it for me.",
            'label': "Cara Heilmann, CEO at Ready Set Go"
        },
        {
            'text': "Video is THE medium in today’s evolving technology. With devices becoming more sophisticated, WeVideo is the perfect addition to help turn you into a pro!",
            'label': "Melissa F., Salon Manager"
        }
    ],
    'business': [
        {
            'text': "WeVideo gives us the ability to scale our video offerings without extensive staff training or design experience.",
            'label': "David Tisdale, Marketing Director at Vx Group"
        },
        {
            'text': "With WeVideo, I can easily create 30-second ads rather than paying someone else $25,000 to do it for me.",
            'label': "Cara Heilmann, CEO at Ready Set Go"
        },
        {
            'text': "Video is THE medium in today’s evolving technology. With devices becoming more sophisticated, WeVideo is the perfect addition to help turn you into a pro!",
            'label': "Melissa F., Salon Manager"
        }
    ],
    'teacher': [
        {
            'text': "I have seen an increased comfort level from teachers and students. The seamless integration with Google Drive and YouTube makes it that much more appealing and a huge benefit to all teachers.",
            'label': "Evan Mosier, Sudlow Intermediate School"
        }
    ]
}

export const PERSONAL_TAB_FAQ = {
    items: [
        {
            header: "How do I upgrade my existing account?",
            content: <div><p>Upgrading is easy! Just click on the upgrade button located on the My Account tab and then follow the steps that appear.</p></div>
        },
        {
            header: "What's included in a WeVideo account?",
            content: <div><p>Every WeVideo account, including free accounts, features a remarkable suite of creative features including Picture-in-Picture and the ability to use your own music, all at no charge. When you upgrade to a premium plan, you gain significantly more publishing time and cloud storage, support for higher resolution video, as well as access to a huge list of additional features such as green screen, slow motion controls, broadcast-quality motion titles, screen recording, and an expanded library of license-free music. <a className="second-primary" href="/pricing" target="_BLANK" rel="noopener noreferrer">More details are available here</a>.</p></div>
        },
        {
            header: "Can I create videos in HD or 4K?",
            content: <div><p>Absolutely! All WeVideo users can edit up to 4K footage in WeVideo. In fact, you can import almost any type of footage and freely mix and match with the more than 600 audio, video, and graphics media formats supported by WeVideo. <a className="second-primary" href="//wevideo.zendesk.com/hc/en-us/articles/210290638-What-type-of-images-videos-are-optimal-for-WeVideo-What-file-formats-are-supported-" target="_BLANK" rel="noopener noreferrer">See the complete list here</a>. Unlimited subscribers can publish their videos in 1080p and 4K Ultra HD at no additional charge. Users with a Flex or Power account can purchase 1080p and 4K publish upgrades on an as-needed basis.</p></div>
        },
        {
            header: "What is publish time?",
            content: <div><p>When you are done editing your video, the next step is to “publish—our way of saying combine all the elements of your movie into a final MP4 file—at the resolution you want, so that you can share it anywhere. Publish time is the duration of your published videos. Look to the <a className="second-primary" href="/pricing?compare">plan comparison chart</a> on total available publish time per plan. For example, 1 hour per month will allow you to publish 20 videos that are each three minutes long, 12 videos that are each five minutes long, or any other combination that adds up to your total available publish time. Publish time is not affected by the amount of time you can spend working in the editor and/or the duration of your media assets. More information is <a className="second-primary" href="//wevideo.zendesk.com/hc/en-us/articles/211013497-What-is-used-time-and-used-storage-Capacity-" target="_BLANK" rel="noopener noreferrer">available here</a>.</p></div>
        },
        {
            header: "What is cloud storage? Can I free up storage space? How do I get more storage?",
            content: <div><p>You can easily keep track of your available storage from the My Account page when you are logged in to WeVideo. The media files uploaded to your account—e.g. video, audio, photos, etc.—take up storage space. Published videos also take up storage space. You can easily reclaim storage space by deleting published videos and media assets that you are no longer using. In addition, more storage is available by upgrading your account.</p></div>
        },
        {
            header: "How do I cancel my subscription? What happens when I cancel?",
            content: <div><p>Subscriptions automatically renew based on the term you selected when you created your account, either monthly or annually, until cancelled. You can cancel your subscription at any time by clicking on the “Cancel Subscription” button on the WeVideo Account tab. When you cancel your subscription, you will retain full access to your account and its full features until the end of your paid term.</p></div>
        },
        {
            header: "Can I rollover unused publish time?",
            content: <div><p><strong>No.</strong> The time for most WeVideo plans gets reset every month. The exceptions to this are Flex plans which are limited to 20 minutes total cumulative publish time used anytime during the 12 month duration of the plan.</p></div>
        },
        {
            header: "Can I add my videos to social media, video sharing and/or file sharing sites?",
            content: <div><p>WeVideo is all about sharing! When you publish your video, WeVideo can simultaneously and automatically send it to your preferred social media and sharing sites such as YouTube and Facebook. You can also download your video for local viewing and sharing.</p></div>
        },
        {
            header: "Can I use my videos created on WeVideo for commercial purposes, including the music?",
            content: <div><p>Absolutely! Your videos are yours to use. Just remember that copyright and trademark laws must still be followed. All music provided from the WeVideo music library is licensed for the free use of our subscribers.</p></div>
        },
        {
            header: "Can I save my brand’s logo, color and fonts?",
            content: <div><p>Yes you can. WeVideo users on Professional and Business plans can upload their brand elements to be available at all times on WeVideo. Users can upload their brand logo to use as a watermark, select colors via the color grid or pick the exact color by entering the hex code and select up to three specific fonts from the WeVideo library that align with your brand.</p></div>
        },
        {
            header: "How can I edit videos for free?",
            content: <div><p>Start making videos for free by downloading WeVideo from the App Store or Google Play Store. Want more features and publish time? Upgrade to one of several paid plans that fits your video needs.</p></div>
        }
    ]
};

export const EDUCATION_TAB_FAQ = {
    items: [
        {
            header: "How is WeVideo for Schools different from the individual and business plans?",
            content: <div><p>WeVideo for Schools was designed specifically for an education environment and schools, teachers and students. The account is managed by school technology or teaching staff and purpose-built to provide students an environment for creation and collaboration that meets strict privacy and security regulations. More than just a video editor, WeVideo sparks creativity with tools for collaboration, green screen, podcasting, simultaneous screen and webcam recording, and much more. Students learn through revision and iteration with peer review tools like inline comments, combined edits which allows students to collaborate on projects.</p></div>
        },
        {
            header: "How many accounts do I need for my class, school or district?",
            content: <div><p>WeVideo for Schools is offered as a multi-user license that is priced per user. When you purchase one license, it provides a bundle of "accounts" (also called "seats"). Therefore you purchase a license based on the number of users you would like to accommodate. Every teacher, student, or administrator using the system will need their own login and should be considered a unique user. Volume discounts are available for school and district purchases, and those discounts increase with the number of users included in an order. The minimum purchase is a 30 seat license. Although there is a minimum number of seats required for an EDU purchase, this plan is designed to accommodate any number of users up to 30.</p></div>
        },
        {
            header: "What is a seat and is one required for every user?",
            content: <div><p>Everyone using WeVideo for Schools (eg. teachers, students and technology staff) requires their own account login. Each login is considered a seat and is a unique account (or user). These seats can be allocated to a user and reallocated to a new person at no additional cost (for example at the end of a school year or when a student leaves a district). <a className="second-primary" href="https://wevideo.zendesk.com/hc/en-us/articles/214581497-Removing-or-deleting-a-member" target="_blank" rel="noopener noreferrer">Learn more</a>.</p></div>
        },
        {
            header: "Can we re-assign seats?",
            content: <div><p>Yes. WeVideo for Schools allows your organization to re-assign seats. This is often done at the start of the year to allow incoming students to take seats that have opened up as last year's students have graduated, changed schools or classes. <a className="second-primary" href="https://wevideo.zendesk.com/hc/en-us/articles/214581497-Removing-or-deleting-a-member" target="_blank" rel="noopener noreferrer">Learn more</a>.</p></div>
        },
        {
            header: "Can users share logins?",
            content: <div><p>No. Every WeVideo for Schools user is a member (or seat) in the account and needs to have their own unique login for their privacy and protection. Login sharing is also a violation of our <a className="second-primary" href="/enterprise-terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>. The same rules apply to WeVideo individual accounts. Every user is required to have their own unique login/seat.</p></div>
        },
        {
            header: "How do you handle student privacy?",
            content: <div><p>We are signatories of the well-known and rigorous <a className="second-primary" href="https://studentprivacypledge.org/" target="_blank" rel="noopener noreferrer">Student Privacy Pledge</a>. Student data is not collected, stored or shared. We are fully compliant with laws protecting the privacy of minors using online services, including COPPA and FERPA federal laws and many state laws. A WeVideo for Schools account is managed by school staff, and allows the account administrator to control student membership, sharing and activities. Our Privacy policy is <a className="second-primary" href="/edu-privacy" target="_blank" rel="noopener noreferrer">here</a>. Our Terms of Use agreement is <a className="second-primary" href="/enterprise-terms-of-use" target="_blank" rel="noopener noreferrer">here</a>.</p></div>
        },
        {
            header: "Do you offer support and/or training?",
            content: <div><p>WeVideo is fully committed to the success of its education users. Our responsive, expert education support staff is available to all paid WeVideo for Schools account holders. Our online <a className="second-primary" href="/education-resources" target="_blank" rel="noopener noreferrer">Resources for Educators</a> provides ready-to-use lesson plans, tutorials, tips and tricks. We also provide <a className="second-primary" href="/academy" target="_blank" rel="noopener noreferrer">WeVideo Academy</a>, a fully stocked web resource full of easy to follow videos on everything from getting started to mastering advanced features. We also offer <a className="second-primary" href="https://pages.wevideo.com/pd-offering-aug-2016" target="_blank" rel="noopener noreferrer">professional development services</a> directly and through some amazing partners. Contact education sales for <a className="second-primary" href="/sales/contactus" target="_blank" rel="noopener noreferrer">more info</a>.</p></div>
        },
        {
            header: "Can we use Chromebook, laptop, desktop, or mobile device to edit on WeVideo?",
            content: <div><p>Absolutely! You can edit complex videos on Chromebook, PC, or Mac and seamlessly continue projects between all of these so students are never bound to a single device. For on-the-go creation, you can use our mobile apps on any iOS and Android device. You can read about our system requirements <a className="second-primary" href="https://wevideo.zendesk.com/hc/en-us/articles/210290298-What-browser-settings-are-best-for-WeVideo-System-Requirements-" target="_blank" rel="noopener noreferrer">here</a>.</p></div>
        },
        {
            header: "Can I just get pricing? I'm not ready to buy",
            content: <div><p>Yes. Visit our <a className="second-primary" href="/pricing#tab_education" target="_blank" rel="noopener noreferrer">pricing for education</a> page and select the number of seats that you need. Click on “Get a quote” and follow the steps. After you complete the form, we will instantly generate a quote for you. When you are ready to purchase, you can retrieve your quote and convert that to an order. Have more questions? <a className="second-primary" href="/education/contact-specialist" target="_blank" rel="noopener noreferrer">Contact WeVideo Education Sales</a>.</p></div>
        },
        {
            header: "How does a school purchase or get a quote from WeVideo?",
            content: <div><p>A school, school district, university or other educational institution can purchase WeVideo for Schools with a purchase order or with a credit card. The price and quote generator on <a className="second-primary" href="/pricing#tab_education" target="_blank" rel="noopener noreferrer">this page</a> will take you through the steps to create a quote or pro forma invoice which you can share, modify or download. You will also find a link to our W9 and payment options. If you need assistance with a purchase, please reach out to us <a className="second-primary" href="/sales/contactus" target="_blank" rel="noopener noreferrer">here</a>.</p></div>
        },
        {
            header: "I am not in charge of payment. What are my next steps?",
            content: <div><p>Visit our <a className="second-primary" href="/pricing#tab_education" target="_blank" rel="noopener noreferrer">pricing for education</a> page, click on “Get a quote”, enter the number of seats you need and follow the steps. After you complete the information to get a quote, we will instantaneously generate a link which you can share with your decision makers and your procurement or business office so that they can complete the payment. You can also download the quote, pro-forma invoice and W9 to share via email or in print. Questions? <a className="second-primary" href="/education/contact-specialist" target="_blank" rel="noopener noreferrer">Contact WeVideo Education Sales</a>.</p></div>
        },
        {
            header: "How do I renew my WeVideo for Schools plan?",
            content: <div><p>We're here to help you. Contact <a className="second-primary" href="/sales/contactus" target="_blank" rel="noopener noreferrer">WeVideo Education Sales</a> and include the name and email address of the school staff member to whom the WeVideo for Schools account is registered.</p></div>
        },
        {
            header: "How do I renew my WeVideo for Teachers plan?",
            content: <div><p>It will autorenew when your annual subscription is over. You may cancel before your subscription is over, if you do not want it to renew.</p></div>
        },
        {
            header: "What are the monthly publishing limitations?",
            content: <div><p>The WeVideo for Teachers plan allows teachers to publish up to 30 hours of video per month. The WeVideo for Schools plan allows every user to publish on average an hour of video per month, which is pooled across all members (i.e. 30 seats will share 30 hours per month). Published video refers to a video edit that is complete and has been rendered into a final video. There is no limit on how long users can work in the application. If you think your school or district will exceed the publishing limit, <a className="second-primary" href="/education/contact-specialist" target="_blank" rel="noopener noreferrer">contact WeVideo Education Sales</a> for assistance.</p></div>
        },
        {
            header: "Does WeVideo for Schools work with G Suite for Education or Office 365?",
            content: <div><p>Yes. WeVideo for Schools has built-in domain level sync with Office 365 and G-Suite. There is additional integration with Google Drive, Google Classroom, and Microsoft OneDrive, with more to come.</p></div>
        },
        {
            header: "Do you have monthly plans?",
            content: <div><p>Yes, but they are not intended for education groups. We know our school users might only do one or two projects per year, therefore our annual plans for education are heavily discounted to accommodate these requirements. Our monthly plans do not offer WeVideo's significant educational discount. It is also important to note that WeVideo for Schools includes significant, purpose-built features for schools. These features are not present in individual WeVideo plans. These capabilities include stringent adherence to privacy and protection policies for students, assessment tools for teachers, and robust administration and management capabilities that scale to meet classroom, campus-wide and entire district requirements.</p></div>
        },
        {
            header: "How does WeVideo comply with the General Data Protection Regulation (GDPR) legislation?",
            content: <div><p>WeVideo is fully compliant with GDPR as of May 25, 2018. We currently collect the following personally identifiable information - first name, last name, and email. The data is stored and accessed securely during the term of the contract. All data access is through secure SSL protocols. WeVideo maintains strict access control to ensure data is only accessible by users who have rights to it. On contract termination, all user data is securely returned and destroyed as directed by the user. WeVideo uses user data only for the purpose of fulfilling its duties under the agreement and does not share such data with or disclose it to any third party, except as required by law. WeVideo certifies that there has never been an instance where there has been a data breach or failure to comply with the data security agreement with a customer. For full details on WeVideo’s GDPR compliance, read our <a className="second-primary" href="/edu-privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p></div>
        },
        {
            header: "What are the cloud storage limits, publishing limits, and export resolution?",
            content: <div><p>All education plans include unlimited creation time in the account. WeVideo for Schools accounts also offer ample publish time and storage. For a 30-seat account, there is 30 hours/month of publishing time and 150 GB of storage shared amongst the users. The publish time and storage increases with additional seats. For the WeVideo for Teachers account, there is 30 hours/month of publishing time and 50 GB of storage. WeVideo for Schools and WeVideo for Teacher plans allows for videos to export in Standard HD.</p></div>
        }
    ]
};

export const PLAYPOSIT_TAB_FAQ = {
    items: [
        {
            header: "What benefits do I get by upgrading to a Pro or Institutional license?",
            content: <div><p>Instructors upgrade to Pro because they want unlimited bulb attempts, the ability to upload videos, get advanced bulb customization options, access powerful analytics tools, and deliver Broadcast or Learner Made Bulbs.</p><p>Organizations upgrade to Institutional licenses because they want to integrate with Learning Management Systems like Canvas, Blackboard, and Schoology, get personalized support for their instructors and learners, and whitelabel the PlayPosit experience.</p></div>
        },
        {
            header: "Can I share accounts with another instructor in my organization?",
            content: <div><p>No, sharing individual accounts is not permitted. Each instructor needs to have their own PlayPosit account to leverage the full benefits of the platform.</p></div>
        },
        {
            header: "What forms of payment do you accept?",
            content: <div><p>Pro accounts can be paid for via credit cards, debit cards, and Apple Pay only.</p></div>
        },
        {
            header: "What happens if I decide to downgrade later on?",
            content: <div><p>As a free user, you’ll still have access to all of your content.</p></div>
        },
        {
            header: "What happens if I go over my video upload limit?",
            content: <div><p>If you go over your video upload limit, you will be charged according to the amount you have gone over. Be aware that if your learners upload video as part of their video attempts, whether learner-made Bulbs or through the rich text editor in the player, then that will be charged to your account.</p></div>
        },
        {
            header: "What counts as a direct upload video? What if I delete the video?",
            content: <div><p>Direct upload videos are videos that have been uploaded to the PlayPosit web app.</p><p>Instructors can directly upload videos as:</p><ul><li>An interactive video source</li><li>Within interactions</li></ul><p>Learners can directly upload videos as:</p><ul><li>Learner-made content's video source</li><li>Within interactions</li><li>A response to any interaction that allows rich text</li></ul><p>You can delete an interactive video or delete/replace it's video source, but it will still count towards your video upload quota.</p></div>
        },
        {
            header: "Do you have any file size or file type restrictions?",
            content: <div><p>PlayPosit creates optimized versions of the following video file types for different devices and connection speeds:</p><ul><li>MP4</li><li>M4V</li><li>MPG</li><li>MOV</li><li>WEBM</li><li>MKV</li><li>AVI</li><li>FLV</li></ul></div>
        }
    ]
}

export const PERSONAL_PLANS_V3 = {
    wv_single_1m: {
        label: "Power",
        price: 9.99,
        bullets: ['30 min publishing time/month','720p resolution','Access to basic stock media','Screen or webcam recording'],
        tier: "personal",
        smallDescription: "Starter tools for single projects.",
    },
    wv_single_plus_1m: {
        label: "Power",
        price: 9.99,
        bullets: ['30 min publishing time/month','720p resolution','Access to basic stock media','Screen or webcam recording'],
        tier: "personal",
        smallDescription: "Starter tools for single projects.",
    },
    wv_single_12m: {
        label: "Power",
        price: 4.99,
        billedAnnually: 59.88,
        bullets: ['30 min publishing time/month','720p resolution','Access to basic stock media','Screen or webcam recording'],
        tier: "personal",
        smallDescription: "Starter tools for single projects.",
    },
    wv_single_plus_12m: {
        label: "Power",
        price: 4.99,
        billedAnnually: 59.88,
        bullets: ['30 min publishing time/month','720p resolution','Access to basic stock media','Screen or webcam recording'],
        tier: "personal",
        smallDescription: "Starter tools for single projects.",
    },
    wv_unloct17_1m: {
        label: "Unlimited",
        promoLabel: "MOST POPULAR",
        price: 15.99,
        bulletsTitle: "Power, plus:",
        bullets: ['Unlimited storage and publishing time','4K Ultra HD resolution','Advanced stock assets','Screen & webcam recording'],
        tier: "personal",
        smallDescription: "An extended toolset for routine personal projects.",
    },
    wv_unloct17_plus_1m: {
        label: "Unlimited",
        promoLabel: "MOST POPULAR",
        price: 15.99,
        bulletsTitle: "Power, plus:",
        bullets: ['Unlimited storage and publishing time','4K Ultra HD resolution','Advanced stock assets','Screen & webcam recording'],
        tier: "personal",
        smallDescription: "An extended toolset for routine personal projects.",
    },
    wv_unloct17_12m: {
        label: "Unlimited",
        promoLabel: "MOST POPULAR",
        price: 7.99,
        billedAnnually: 95.88,
        bulletsTitle: "Power, plus:",
        bullets: ['Unlimited storage and publishing time','4K Ultra HD resolution','Advanced stock assets','Screen & webcam recording'],
        tier: "personal",
        smallDescription: "An extended toolset for routine personal projects.",
    },
    wv_unloct17_plus_12m: {
        label: "Unlimited",
        promoLabel: "MOST POPULAR",
        price: 7.99,
        billedAnnually: 95.88,
        bulletsTitle: "Power, plus:",
        bullets: ['Unlimited storage and publishing time','4K Ultra HD resolution','Advanced stock assets','Screen & webcam recording'],
        tier: "personal",
        smallDescription: "An extended toolset for routine personal projects.",
    },
    wv_bizindiv_1m: {
        label: "Professional",
        promoLabel: "BEST VALUE",
        price: 39.99,
        bulletsTitle: "Unlimited, plus:",
        bullets: ['Unlimited stock usage','Premium templates','Brand management'],
        tier: "business",
        smallDescription: "Full-scale editing tools to make pro content.",
    },
    wv_bizindiv_12m: {
        label: "Professional",
        promoLabel: "BEST VALUE",
        price: 19.99,
        billedAnnually: 239.88,
        bulletsTitle: "Unlimited, plus:",
        bullets: ['Unlimited stock usage','Premium templates','Brand management'],
        tier: "business",
        smallDescription: "Full-scale editing tools to make pro content.",
    },
    wv_bizoct17_1m: {
        label: "Business",
        price: 73.99,
        bulletsTitle: "Professional, plus:",
        bullets: ['Create your own templates','Collaborate in real time','Share projects and assets'],
        tier: "business",
        smallDescription: "Collaborative video editing tools for teams of 3 or less.",
    },
    wv_bizoct17_12m: {
        label: "Business",
        price: 36.99,
        billedAnnually: 443.88,
        bulletsTitle: "Professional, plus:",
        bullets: ['Create your own templates','Collaborate in real time','Share projects and assets'],
        tier: "business",
        smallDescription: "Collaborative video editing tools for teams of 3 or less.",
    },
}