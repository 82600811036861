import Track from "./Track";
import API from "../api/Api";
import {
    getOrderState,
    getProductCode,
    getUserId, hasGtmEnabled,
    isAuth
} from "../../helpers/AppHelper";
import Product from "../../models/Product";
import FacebookTrack from "./FacebookTrack";
import {APP_HASH_PATH, APP_HASH_PATH_ROUTE_INDEX, WEVIDEO_PLANS} from "../../constants/AppGlobal";
import {getPageValueForCheckoutABTest, loadedInIframe} from "../../helpers/GeneralHelper";
import {PLAN_SELECTED_TIER_PERSONAL} from "../../constants/Plans";
import HubSpotTrack from "./HubSpotTrack";

window.dataLayer = window.dataLayer || [];

class GeneralTrack {
    static instance;

    static getInstance = () => {
        if(!GeneralTrack.instance) {
            GeneralTrack.instance = new GeneralTrack()
        }

        return GeneralTrack.instance;
    };

    trackSummary = (selectedProduct, orderTotal, tier, page) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        Track.trackEvent("page_view", {
            page: page,
        });

        const product = new Product(selectedProduct);

        const price = orderTotal,
            name = product.getName(),
            code = product.getCode();

        const addToCartTracked = sessionStorage.getItem("addToCartTracked");
        if (addToCartTracked !== "true") {
            sessionStorage.setItem("addToCartTracked", "true");
            window.dataLayer.push(this.trackAddToCartToDataLayer(code, price, name, tier));
        }

        if(product.getHasPlan()) {
            window.dataLayer.push({
                'event': 'checkout',
                'ecommerce': {
                    'checkout': {
                        'actionField': {'step': 1},
                        'products': [{
                            'name': name,
                            'id': code,
                            'price': price,
                            'quantity': 1
                        }]
                    }
                }
            });
        }

        FacebookTrack.productAddToCart(price, code);
        HubSpotTrack.trackHubspotEvent("000006931432", {product: code}, true);
    };

    trackAddToCartToDataLayer = (code, price, name, tier) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        code = code || WEVIDEO_PLANS.FREE_PLAN;
        price = price || 0;
        name = name || 'free';

        let periodStr = 'Monthly',
            listStr = 'Individuals and Businesses - ';
        if (code.indexOf('12m') !== -1) {
            periodStr = 'Annual';
        }

        if (tier === 'education') {
            listStr = 'Schools and Districts';
            periodStr = '';
        }

        return {
            'event': 'addToCart',
            'ecommerce': {
                'add': {
                    'actionField': {'list': listStr + '' + periodStr},
                    'products': [{
                        'name': name,
                        'id': code,
                        'price': price,
                        'position': 1
                    }]
                }
            },
            'label': code
        }
    };

    trackImpressionsToDataLayer = (impressions) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        window.dataLayer.push({
            'event': 'impression',
            'ecommerce': {
                'impressions': impressions
            }
        });
    };

    processSessionStorageEvents = () => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        if (isAuth()) {
            /* Got any queued events? */
            if(window.sessionStorage && window.sessionStorage.eventQueue){
                let queue = JSON.parse(window.sessionStorage.eventQueue);
                for(let i = 0; i < queue.length; i++) {
                    let properties = this.fixEventPropertiesParams(queue[i].data);
                    GeneralTrack.getInstance().sendMixpanelEvent(queue[i].id, properties);
                }
                window.sessionStorage.eventQueue = [];
            }
        }
    };

    fixEventPropertiesParams = (params) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        if (typeof params !== 'undefined' && params !== null) {
            if (params.hasOwnProperty('UserId') && params.UserId === 0) {
                params.UserId = getUserId();
            }
            if (params.hasOwnProperty('Product') && params.Product === false) {
                params.Product = getProductCode();
            }
            if (params.hasOwnProperty('OrderState') && params.OrderState === 'unknown') {
                params.OrderState = getOrderState();
            }
            if (params.hasOwnProperty('page_url')) {
                if (window.location.pathname === '/cart' &&
                    (window.location.hash === '' || window.location.hash === '#_=_' || window.location.hash === '#') &&
                    window.location.search === '' &&
                    !loadedInIframe()) {
                    // weird google and facebook signup redirects
                    params.page_url = window.location.origin + APP_HASH_PATH_ROUTE_INDEX + APP_HASH_PATH.PLANS_PAGE + "?tab=" + PLAN_SELECTED_TIER_PERSONAL;
                }
            }
            let pageUrl = params.page_url || window.location.href;
            params['page'] = getPageValueForCheckoutABTest(pageUrl);
        }

        return params;
    };

    sendMixpanelEvent = (eventName, properties) => {
        if (hasGtmEnabled() === false) {
            return false;
        }

        let api = API.create();
        let jsonObj = {};
        jsonObj.eventName = eventName;
        jsonObj.properties = properties;

        api.sendMixpanelEvent(jsonObj).then(evt => {
            console.log('Data tracked to Mixpanel: ', evt);

            let eventName = jsonObj.eventName + ":finished-sending";
            const event = new CustomEvent(eventName);
            document.dispatchEvent(event);
        });
    };

    loadProfitWellScripts = (stripeId) => {
        if (hasGtmEnabled() === false) {
            return false;
        }
        if (stripeId) {
            window.dataLayer.push({ 'pw_user_id': stripeId });
            window.dataLayer.push({ 'event': 'userHasStripeId' });
        }
    }
}

export default GeneralTrack;